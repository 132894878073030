import { getSuccessMessage, getFailureMessage, getValues } from '../../store/floating-alerts/reducers'
import { closeMessage } from '../../store/floating-alerts/actions'
import { connect } from 'react-redux'
import FloatingAlert from './FloatingAlert'

const mapStateToProps = (state, { match }) => {
    return {
        sucessMessage: getSuccessMessage(state),
        failureMessage: getFailureMessage(state),
        values: getValues(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(closeMessage())
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FloatingAlert)
