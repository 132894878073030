import { connect } from 'react-redux'

import Alerts from './Alerts'
import { getAlert, getStatus } from '../../store/login/reducers'

const mapStateToProps = (state,ownProps) => {
    return {
        alert: getAlert(state),
        status: ownProps.status
        // status: getStatus(state)
    }
}

const AlertsContainer = connect(mapStateToProps, null)(Alerts)

export default AlertsContainer
