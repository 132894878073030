export const initState = {
    buyItems: [],
    expansionsList: [],
    rarityList: [],
    buyItem: null,
    page: 0,
    pagesCount: 0,
    pageSize: 10,
    total: 0,
    error: null,
    isSending: false,
    isLoading: false,
    errors: null,
}

const buy = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_BUY_ITEMS_REQUEST':
        case 'FETCH_BUY_ITEMS_FROM_USER_REQUEST':
            return { ...state, error: null, isLoading: true, errors: null }
        case 'FETCH_BUY_ITEMS_SUCCESS':
        case 'FETCH_BUY_ITEMS_FROM_USER_SUCCESS':
            const expansionList = action.expansionsList?.map(expansion => ({ value: expansion.expansionCode, label: `${expansion.expansionName} (${expansion.cardsCount})` })) ?? [];
            const rarityList = action.rarityList?.map((rarity) => ({ value: rarity.rarity, label: `${rarity.rarity} (${rarity.cardsCount})` })) ?? []
            return {
                ...state,
                buyItems: action.buyItems.current_page === 1 ? action.buyItems.data : state.buyItems.concat(action.buyItems.data),
                page: action.buyItems.current_page,
                pagesCount: action.buyItems.last_page,
                pageSize: action.buyItems.per_page,
                total: action.buyItems.total,
                expansionsList: [{value: null, label: 'Any'}, ...expansionList],
                rarityList: [{value: null, label: 'Any'}, ...rarityList],
                isLoading: false
            }
        case 'FETCH_BUY_ITEMS_FAILURE':
        case 'FETCH_BUY_ITEMS_FROM_USER_FAILURE':
            return { ...state, error: action.response, errors: null }
        case 'FETCH_BUY_ITEM_REQUEST':
            return { ...state, buyItem: null, error: null, isLoading: true, errors: null }
        case 'FETCH_BUY_ITEM_SUCCESS':
            return { ...state, buyItem: action.buyItem, error: null, isLoading: false, errors: null }
        case 'FETCH_BUY_ITEM_FAILURE':
            return { ...state, buyItem: null, error: action.response, isLoading: false, errors: null }
        case 'ADD_TO_BUY_REQUEST':
            return { ...state, isSending:true, errors: null}
        case 'ADD_TO_BUY_SUCCESS':
            return { ...state, isSending:false, errors: null}
        case 'ADD_TO_BUY_FAILURE':
            return { ...state, isSending:false, errors: action.errors}
        case 'EDIT_ITEM_FROM_BUY_REQUEST':
            return { ...state, isSending:true, errors: null}
        case 'EDIT_ITEM_FROM_BUY_SUCCESS':
            return { ...state, isSending:false, errors: null}
        case 'EDIT_ITEM_FROM_BUY_FAILURE':
            return { ...state, isSending:false, errors: action.errors}
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export const findBuyItemById = (state, id) => {
    if (state.buy.buyItem?.id == id) {
        return state.buy.buyItem
    }
    if(!state.buy.buyItems || !state.buy.buyItems.length){
        return null
    }
    let found = state.buy.buyItems.find((item) => {
        return item.id == id
    })
    // console.log(found)
    return found
}
export const getError = (state) => {
    return state.products.error
}

export const getBuyItems = (state, id) => {
    return state.buy.buyItems
}

export const getBuyItemsTableInfo = (state) => {
    const buyItemsState = state.buy;
    const {page, pagesCount, pageSize, total} = buyItemsState;
    return { page, pagesCount, pageSize, total }
}

export const getBuyItemsExpansionsAvailableList = (state) => {
    return state.buy.expansionsList;
}
export const getBuyItemsRarityAvailableList = (state) => {
    return state.buy.rarityList
}

export const getIsLoading = (state) => {
    return state.buy.isLoading
}
export const isSending = (state) => {
    return state.buy.isSending
}
export const getBuyErrorForms = (state) => {
    return state.buy.errors
}
export default buy
