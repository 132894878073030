import React from 'react'

class Spinner extends React.Component {
    render() {
        let className = 'sk-cube-grid '
        if (this.props.relative) className += 'spinner-relative '
        if (this.props.small) className += 'spinner-small '
        const spinner = (
            <div className={className} data-cy="global__sk_cube_grid">
              <div>
  <div className='sk-wandering-cubes'>
    <div className='sk-cube sk-cube-1'></div>
    <div className='sk-cube sk-cube-2'></div>
  </div>
</div>
                
            </div>
        )
        if (!this.props.isLoading) {
            return null
        } else {
            return this.props.relative ? (
                spinner
            ) : (
                <div className="full-overlay" style={{ display: 'block' }}>
                    {spinner}
                </div>
            )
        }
    }
}
export default Spinner
