import React, { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../../../global/Translation'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '../../../global/Button'
import { changePassword, updatePersonalInformation } from '../../../../store/login/actions'
import { getEmail, getIsBusiness, getUserName, getVatNumber, getVatPrefix } from '../../../../store/login/reducers'
import Alerts from '../../../alerts/Alerts'
import PersonalInformationForm from './PersonalInformationForm'


const ChangePasswordForm = (props) => {

    const dispatch = useDispatch()
    const error = useSelector(state => state.login.alert)

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        newPassword_confirmation: ''
    }


    const validationSchema = Yup.object({
        currentPassword: Yup.string()
            .required(props.intl.formatMessage({ id: 'REQUIRED' })),
        newPassword_confirmation: Yup.string()
            .oneOf([
                Yup.ref('newPassword'), ''], props.intl.formatMessage({ id: 'PASSWORDS_MUST_MATCH' }))
            .required(props.intl.formatMessage({ id: 'REQUIRED' }))
            .min(6, props.intl.formatMessage({ id: 'MUST_BE_6_CHARACTERS_OR_MORE' }))
    })


    const onSubmit = (values, action) => {
        dispatch(changePassword(values))
        action.resetForm({
            values: {
                currentPassword: '',
                newPassword: '',
                newPassword_confirmation: ''
            }
        })
    }


    const handleClickSubmitChangePassword = event => {
        formik.submitForm()
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })



    return (
        <React.Fragment>
            <div className="">
                <div className="row">
                    <div className="col-12">
                        <form className="form">
                            <div className="mb-3">
                                <label htmlFor="current_password" className="form-label">
                                    <Translation id="current_password" defaultMessage="Current password " /> *
                                </label>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="currentPassword"
                                    name="currentPassword"
                                    required="required"
                                    placeholder={props.intl.formatMessage({ id: 'AT_LEAST_6_CHARACTERS' })}
                                    pattern=".{6,}"
                                    value={formik.values.currentPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.currentPassword ? <div style={{ color: 'red' }}>{formik.errors.currentPassword}</div> : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="newPassword" className="form-label">
                                    <Translation id="newPassword" defaultMessage="New password " />
                                </label>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    placeholder={props.intl.formatMessage({ id: 'AT_LEAST_6_CHARACTERS' })}
                                    pattern=".{6,}"
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.newPassword && formik.errors.newPassword ? <div style={{ color: 'red' }}>{formik.errors.newPassword}</div> : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="newPassword_confirmation" className="form-label">
                                    <Translation id="confirm_newPassword" defaultMessage="Confirm new password" />
                                </label>
                                <input
                                    type="password"
                                    id="newPassword_confirmation"
                                    name="newPassword_confirmation"
                                    placeholder={props.intl.formatMessage({ id: 'WRITE_THE_PASSWORD_AGAIN' })}
                                    pattern=".{6,}"
                                    className="form-control"
                                    value={formik.values.newPassword_confirmation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.newPassword_confirmation && formik.errors.newPassword_confirmation ? (
                                    <div style={{ color: 'red' }}>{formik.errors.newPassword_confirmation}</div>
                                ) : null}
                                {error && (
                                    <Fragment>
                                        <Alerts alert={error} status="404" />
                                    </Fragment>
                                )}
                            </div>
                            <div className="mb-3">
                                <Button type="submit" disabled={!formik.dirty || !formik.isValid} onClick={handleClickSubmitChangePassword}>
                                    <Translation id="update" defaultMessage="Update" />
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(ChangePasswordForm)))
