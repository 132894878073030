import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

export class CandcWebSocket {
    echoConnection = null

    getEchoConnection() {
        return this.echoConnection
    }

    disconnect() {
        if (!this.echoConnection) {
            return
        }

        this.echoConnection.leaveAllChannels()

        setTimeout(() => {
            this.echoConnection.disconnect()
            delete this.echoConnection
            this.echoConnection = null
            console.log('ws disconnected')
        }, 1000)
    }

    createSocketConnection(userId, bearerToken) {
        if (this.echoConnection) {
            return
        }

        const key = process.env.REACT_APP_WS_KEY
        let wsHost = process.env.REACT_APP_WS_HOST
        let wsPath = null
        const authEndpoint = process.env.REACT_APP_API_HOST + '/api/broadcasting/auth'
        const wsPort = process.env.REACT_APP_WS_HTTP_PORT
        const wssPort = process.env.REACT_APP_WS_HTTPS_PORT
        const forceTLS = process.env.NODE_ENV === 'production'

        if (process.env.NODE_ENV === 'production') {
            wsHost = `${window.location.hostname}`
            wsPath = '/ws'
        }

        this.echoConnection = new Echo({
            broadcaster: 'pusher',
            cluster: 'candc1',
            key,
            wsHost,
            wsPath,
            wsPort,
            wssPort,
            forceTLS,
            disableStats: true,
            authEndpoint,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + bearerToken
                }
            }
        })
    }

}