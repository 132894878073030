import Translation from '../global/Translation'
import moment from 'moment/moment'
import React from 'react'
import LocalizedLink from '../global/LocalizedLink'

export const VotesAndHistoricTable = ({ votesAndHistoric, showOrderLink = false }) => {
    return <div className="card-list">
        {votesAndHistoric.map((item => {
            return (
                <div key={item.id} className="card table-row">
                    <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                        {showOrderLink && (
                            <div>
                                {item.order_id ? (
                                    <LocalizedLink routeId="ROUTE_ORDER" params={{ id: item.order_id }}>
                                        <span className="material-icons-outlined" style={{ fontSize: '32px' }}>
                                            info
                                        </span>
                                    </LocalizedLink>
                                ) : (
                                    <span>-</span>
                                )}
                            </div>
                        )}
                        <div>
                            <span>
                                <Translation id="buyer" defaultMessage="Buyer" />
                            </span>
                            <strong className="card-title">{item.name}</strong>
                        </div>
                        <div>
                            <span>
                                <Translation id="date" defaultMessage="Date" />
                            </span>
                            {moment(item.finished_at).format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="properties">
                            <div style={{ alignItems: 'center' }}>
                                <Translation id="vote_seller_general" defaultMessage="General" />
                                <VoteFace vote={item.vote_seller_general}></VoteFace>
                            </div>
                            <div style={{ alignItems: 'center' }}>
                                <Translation id="vote_seller_packaging" defaultMessage="Packaging" />
                                <VoteFace vote={item.vote_seller_packaging}></VoteFace>
                            </div>
                            <div style={{ alignItems: 'center' }}>
                                <Translation id="vote_seller_description" defaultMessage="Description" />
                                <VoteFace vote={item.vote_seller_description}></VoteFace>
                            </div>

                            <div style={{ alignItems: 'center' }}>
                                <Translation id="amount" defaultMessage="Amount" />
                                <OrderAmount order_amount={item.order_amount}></OrderAmount>
                            </div>
                            {item.vote_seller_comments && (
                                <div style={{ alignItems: 'center' }}>
                                    <Translation id="vote_seller_comments" defaultMessage="Comments" />
                                    {item.vote_seller_comments}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )
 }))}

    </div>
    {/* return (<ReactTable
        data={votesAndHistoric}
        columns={[
            {
                Header: <Translation id='order' defaultMessage='Order' />,
                accessor: 'order_id',
                minWidth: 30,
                show: showOrderLink,
                Cell: ({ original }) => {
                    return (
                        original.order_id ?
                        <LocalizedLink routeId='ROUTE_ORDER' params={{ id: original.order_id }}>
                            <span className='material-icons-outlined' style={{ fontSize: '32px' }}>
                                info
                            </span>
                        </LocalizedLink>
                            : <span>-</span>
                    )
                }
            },
            {
                Header: <Translation id='name' defaultMessage='Name' />,
                accessor: 'name',
                minWidth: 80
            },
            {
                Header: <Translation id='date' defaultMessage='Date' />,
                accessor: 'finished_at',
                minWidth: 60,
                Cell: ({ original }) => {
                    return moment(original.finished_at).format('DD/MM/YYYY')
                }
            },
            {
                Header: <Translation id='vote_seller_general' defaultMessage='General' />,
                accessor: 'vote_seller_general',
                minWidth: 40,
                Cell: ({ original }) => {
                    return <VoteFace vote={original.vote_seller_general}></VoteFace>
                }
            },
            {
                Header: <Translation id='vote_seller_packaging' defaultMessage='Packaging' />,
                accessor: 'vote_seller_packaging',
                minWidth: 40,
                Cell: ({ original }) => {
                    return <VoteFace vote={original.vote_seller_packaging}></VoteFace>
                }
            },
            {
                Header: <Translation id='vote_seller_description' defaultMessage='Description' />,
                accessor: 'vote_seller_description',
                minWidth: 50,
                Cell: ({ original }) => {
                    return <VoteFace vote={original.vote_seller_description}></VoteFace>
                }
            },
            {
                Header: <Translation id='vote_seller_comments' defaultMessage='Comments' />,
                accessor: 'vote_seller_comments',
                minWidth: 200
            },
            {
                Header: <Translation id='amount' defaultMessage='Amount' />,
                accessor: 'order_amount',
                minWidth: 40,
                Cell: ({ original }) => {
                    return <OrderAmount order_amount={original.order_amount}></OrderAmount>
                }
            }
        ]}
        defaultPageSize={votesAndHistoric.length < 10 ? votesAndHistoric.length : 10}
        pageSizeOptions={[10, 25, 50, 100]}
        previousText={<Translation id='previous' defaultMessage='Previous' />}
        nextText={<Translation id='next' defaultMessage='Next' />}
        noDataText={<Translation id='no_data' defaultMessage='No data available' />}
        pageText={<Translation id='page' defaultMessage='Page' />}
        ofText={<Translation id='of' defaultMessage='Of' />}
        className='light table'
        key={votesAndHistoric.length}
        getTrProps={(state, rowInfo) => {
            let cursor = rowInfo ? 'pointer' : 'default'
            return {
                style: { cursor: cursor, background: 'white' }
            }
        }}
    />) */}
}

const VoteFace = ({ vote }) => {
    if (vote === 5) {
        return <i className='fas fa-grin' style={{ color: '#238823' }}></i>
    }
    if (vote === 4) {
        return <i className='fas fa-grin' style={{ color: '#238823' }}></i>
    }
    if (vote === 3) {
        return <i className='fas fa-meh' style={{ color: '#FFBF00' }}></i>
    }
    if (vote === 2) {
        return <i className='fas fa-meh' style={{ color: '#FFBF00' }}></i>
    }
    if (vote === 1) {
        return <i className='fas fa-frown' style={{ color: '#D2222D' }}></i>
    }
    if (vote <= 0) {
        return <i className='fas fa-frown' style={{ color: '#D2222D' }}></i>
    }
}

const OrderAmount = ({ order_amount }) => {
    const orderAmountLabel = {
        LOW: {
            label: '< S$50', icon: 'fa fa-coins'
        }, MIDDLE: {
            label: 'S$50 - S$150', icon: 'fa fa-money-bill'
        }, HIGH: {
            label: '> S$150', icon: 'fa fa-money-bill'
        }
    }
    const currentOrderAmount = orderAmountLabel[order_amount]
    if (order_amount === 'HIGH') {
        return (<span role='img' alt={currentOrderAmount.label}>
                <i className={currentOrderAmount.icon}></i>
                <i className={currentOrderAmount.icon}></i>
                <i className={currentOrderAmount.icon}></i>
            </span>)
    }
    return <i role='img' alt={currentOrderAmount.label} className={currentOrderAmount.icon}></i>
}
