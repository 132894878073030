import { getCollectableContextOptionByContextValue } from './../store/products/CollectableContext';
import { getCurrentCollectableContext } from './../store/products/reducers';
import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { useParams, withRouter } from 'react-router-dom'
import { fetchUserCommentsById, fetchUserPublicInfoById } from '../store/user/actions'
import Translation from '../components/global/Translation'
import { getTradingInfo, getUserPublicComments, getUserPublicInfo, getUserPublicVotes } from '../store/user/reducers'
import { getBankData, getUserId, getVerified } from '../store/login/reducers'
import { createChat } from '../store/chats/actions'
import Button from '../components/global/Button'
import Spinner from '../components/global/Spinner'
import { VotesAndHistoricTable } from '../components/public-profile/VotesAndHistoricTable'
import { WantToSellOrBuy } from '../components/public-profile/WantToSellOrBuy'
import { ProfileLogo } from '../components/global/ProfileLogo'


defineMessages({
    TOKEN_HAS_EXPIRED: {
        id: 'not_orders_finished', description: 'not_orders_finished', defaultMessage: 'User hasn\'t orders finished'
    }
})


export const Votes = ({ votes }) => {
    let numberFormat = new Intl.NumberFormat('en', { maximumFractionDigits: 1, minimumFractionDigits: 1 })
    return (
        <React.Fragment>
            <span className="votes-star d-none d-lg-block" style={{ flexDirection: 'row', border: 'none' }}>
                <VoteStar value={votes} />
                <VoteStar value={votes - 1} />
                <VoteStar value={votes - 2} />
                <VoteStar value={votes - 3} />
                <VoteStar value={votes - 4} />
            </span>
            <span className="votes-star d-inline d-lg-none" style={{ flexDirection: 'row', border: 'none' }}>
                {numberFormat.format(votes)}
                <VoteStar value={votes / 5} />
            </span>
        </React.Fragment>
    )
}

const VoteStar = ({ value }) => {
    if (value <= 0) {
        return <i className='far fa-star' style={{ color: '#1C1B1F' }}></i>
    }

    if (value > 0 && value <= 0.5) {
        return <i className='fas fa-star-half-alt' style={{ color: '#1C1B1F' }}></i>
    }

    return <i className='fas fa-star' style={{ color: '#1C1B1F' }}></i>
}

const PublicProfileScreen = () => {

    const dispatch = useDispatch()
    let params = useParams()
    useEffect(() => {
        dispatch(fetchUserPublicInfoById(params.userNameSlug))
        dispatch(fetchUserCommentsById(params.userNameSlug))
    }, [params.userNameSlug])

    const userLoggedId = useSelector(state => getUserId(state))
    const publicProfileUserInfo = useSelector(state => getUserPublicInfo(state))
    const tradingInfo = useSelector(state => getTradingInfo(state))
    const votes = useSelector(state => getUserPublicVotes(state))
    const comments = useSelector(state => getUserPublicComments(state))
    const verified = useSelector(state => getVerified(state))
    const bankData = useSelector(state => getBankData(state))
    const collectableContext = useSelector(state => getCurrentCollectableContext(state))
    const collectableContextName = useSelector((state) => getCollectableContextOptionByContextValue(collectableContext))


    const publicProfileUserId = publicProfileUserInfo?.id

    const openChat = () => {
        dispatch(createChat(userLoggedId, publicProfileUserId))
    }

    const isMyPublicProfile = !publicProfileUserId ? true : parseInt(userLoggedId) === parseInt(publicProfileUserId)

    return (
        <React.Fragment>
            <div className="container-fluid public-profile-header">
                <div className="row">
                    <div className="public-profile-header__panel-left col-12 col-lg-3 col-xl-4">
                        <h3 className="card-title mt-2 d-flex align-items-center">
                            <ProfileLogo userName={publicProfileUserInfo && publicProfileUserInfo.name} />
                            <span
                                style={{
                                    margin: '0 8px',
                                }}
                            >
                                {publicProfileUserInfo && publicProfileUserInfo.name} ({tradingInfo?.votesCount ?? 0})
                            </span>
                        </h3>
                        {!isMyPublicProfile && verified && bankData?.validated && (
                            <div
                                style={{
                                    maxWidth: '255px',
                                    marginTop: '16px',
                                }}
                            >
                                <Button
                                    className="d-flex align-items-center justify-content-center"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        openChat()
                                    }}
                                >
                                    <span className="material-icons-outlined" style={{ fontSize: '32px' }}>
                                        chat
                                    </span>
                                    <span className="ms-1">Contact by chat</span>
                                </Button>
                            </div>
                        )}
                    </div>

                    <div className="public-profile-header__panel-right col-12 col-lg-9 col-xl-8">
                        <div className="trading-info">
                            <Translation id="sales" defaultMessage="Sales" />
                            <span>{tradingInfo ? tradingInfo?.salesCount : 0}</span>
                        </div>
                        <div className="trading-info">
                            <Translation id="purchases" defaultMessage="Purchases" />
                            <span>{tradingInfo ? tradingInfo?.purchasesCount : 0}</span>
                        </div>

                        {!tradingInfo?.purchasesCount && !tradingInfo?.salesCount ? (
                            <div className="d-flex align-items-center">
                                <Translation id="not_orders_finished" />
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="votes-info">
                                    <Translation id="vote_seller_general" defaultMessage="General" />
                                    {votes && votes.vote_seller_general && <Votes votes={votes.vote_seller_general} />}
                                </div>
                                <div className="votes-info">
                                    <Translation id="vote_seller_descriptio" defaultMessage="Description" />
                                    {votes && votes.vote_seller_description && <Votes votes={votes.vote_seller_description} />}
                                </div>
                                <div className="votes-info">
                                    <Translation id="vote_seller_packaging" defaultMessage="Packaging" />
                                    {votes && votes.vote_seller_packaging && <Votes votes={votes.vote_seller_packaging} />}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <h4 style={{ textTransform: 'capitalize' }}>{collectableContextName.label.toLowerCase()} Articles</h4>

                <div className="d-flex flex-row align-content-around row">
                    <div className="col-12 col-md-6">
                        <h5 className="card-title col-centered">Want to sell</h5>
                        <hr/>
                        <div className="p-4 row" style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
                            {tradingInfo ?
                                tradingInfo.saleItems.map((item) => (
                                    (item.count >  0 || item.category.includes('single')) && <WantToSellOrBuy
                                        type={'SALE'}
                                        itemsCount={item.count || 0}
                                        productInfo={item.randomProductInfo || null}
                                        userToFilter={publicProfileUserId}
                                        category={item.category}
                                    />
                                )):<Spinner isLoading={true} relative={true}></Spinner>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <h5 className="card-title col-centered">Want to buy</h5>
                        <hr/>
                        <div className="p-4 row">
                            {tradingInfo ?
                            <WantToSellOrBuy
                                type={'BUY'}
                                itemsCount={tradingInfo?.buyItems?.count || 0}
                                productInfo={tradingInfo?.buyItems?.randomProductInfo || null}
                                userToFilter={publicProfileUserId}
                            />: <Spinner isLoading={true} relative={true}></Spinner>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid public-profile-votes-table">
                <h4>
                    <Translation id="votesAndHistoric" defaultMessage="Votes & Historic" />
                </h4>
                {comments && <VotesAndHistoricTable votesAndHistoric={comments} showOrderLink={isMyPublicProfile} />}
            </div>
        </React.Fragment>
    )
}

export default withRouter(injectIntl(connect()(PublicProfileScreen)))
