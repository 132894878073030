import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../../../global/Translation'
import { setNestedObjectValues, useFormik } from 'formik'
import Button from '../../../global/Button'
import * as Yup from 'yup'
import { getBankData, getHasFilledShippingAddress } from '../../../../store/login/reducers'
import { updateFinancialInformation } from '../../../../store/login/actions'
import Alerts from '../../../alerts/Alerts'

const FinancialInformationForm = (props) => {

    const dispatch = useDispatch()
    const bankData = useSelector(state => getBankData(state))

    const initialValues = {
        bank_id: bankData && bankData.bank_id ? bankData.bank_id : '',
        account_holder: bankData && bankData.account_holder ? bankData.account_holder : '',
        account_number: bankData && bankData.account_number ? bankData.account_number : '',
    }

    const validationSchema = Yup.object({
        bank_id: Yup.number().required(props.intl.formatMessage({ id: 'REQUIRED' })).nullable(),
        account_holder: Yup.string().required(props.intl.formatMessage({ id: 'REQUIRED' })).nullable(),
        account_number: Yup.string().required(props.intl.formatMessage({ id: 'REQUIRED' })).nullable(),
    })

    const onSubmit = (values, action) => {
        dispatch(updateFinancialInformation(values))
        action.resetForm({ values })
    }
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    useEffect(async () => {
        if (props.bankDataErrors) {
            await formik.setTouched(setNestedObjectValues(props.bankDataErrors, true), false)
            formik.setErrors(props.bankDataErrors)
        }
    }, [props.bankDataErrors])

    const handleClickSubmit = event => {
        formik.submitForm()
    }

    if(!props.hasFilledShippingAddress) {
        return <React.Fragment>
            <div className='col-12'>
                    <div className='row'>
                        <h3>
                            <Translation id='persona_bank_info' defaultMessage='Personal Bank Information' />
                        </h3>
                        <Alerts alert={"please_fill_your_shipping_addres"} status={"warning"} />
                    </div>
            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className='col-12'>
                <form className='form'>
                    <div className=''>
                        <h3>
                            <Translation id='persona_bank_info' defaultMessage='Personal Bank Information' />
                        </h3>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mb-3'>
                                    <div className='col-md-6'>
                                        <label htmlFor='bankName' className='form-label'>
                                            <Translation id='bank_name' defaultMessage='Bank name' />*
                                        </label>
                                        <select
                                            className='form-control mb-3'
                                            type='text'
                                            id='bank_id'
                                            name='bank_id'
                                            autoComplete='off'
                                            placeholder={props.intl.formatMessage({ id: 'bank_name' })}
                                            value={formik.values.bank_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option value=''>Select</option>
                                            {
                                                props.banksOptions && props.banksOptions.map((bank, index) => {
                                                    return (
                                                        <option
                                                            key={bank.id}
                                                            value={bank.id}
                                                            {...(formik.values.bank_id === bank.id ? 'selected' : '')}
                                                        >{bank.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {formik.touched.bank_name && formik.errors.bank_name ? (
                                            <div style={{ color: 'red' }}>{formik.errors.bank_name}</div>
                                        ) : null
                                        }
                                    </div>
                                    <div className='col-md-6'>
                                        <label htmlFor='accountHolder' className='form-label'>
                                            <Translation id='account_holder' defaultMessage='Account holder' />*
                                        </label>
                                        <input
                                            className='form-control mb-3'
                                            type='text'
                                            id='account_holder'
                                            name='account_holder'
                                            autoComplete='off'
                                            placeholder={props.intl.formatMessage({ id: 'account_holder' })}
                                            value={formik.values.account_holder}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.account_holder && formik.errors.account_holder ? (
                                            <div style={{ color: 'red' }}>{formik.errors.account_holder}</div>
                                        ) : null
                                        }
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-md-6'>
                                        <label htmlFor='account_number' className='form-label'>
                                            <Translation id='account_number' defaultMessage='Account number' />*
                                        </label>
                                        <input
                                            className='form-control mb-3'
                                            type='text'
                                            id='account_number'
                                            name='account_number'
                                            autoComplete='off'
                                            placeholder={props.intl.formatMessage({ id: 'account_number' })}
                                            value={formik.values.account_number}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.account_number && formik.errors.account_number ? (
                                            <div style={{ color: 'red' }}>{formik.errors.account_number}</div>
                                        ) : null
                                        }
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col'>
                                        <Button type='submit' disabled={!formik.dirty || !formik.isValid}
                                                onClick={handleClickSubmit}>
                                            <Translation id='Save' defaultMessage='Save' />
                                        </Button>
                                    </div>
                                </div>
                                {props.validationAlert}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(FinancialInformationForm)))
