import Translation from '../../global/Translation'
import React, { useState, useEffect } from 'react'
import noImage from '../../../assets/img/advanced-search/advanced-search.jpg'

const SealedProductCategory = ({ categoryKey }) => {
    const mapCategoryToLabels = {
        booster_box: 'Booster box',
        booster_case: 'Booster case',
        booster_pack: 'Booster pack',
        box_set: 'Box set',
        bundle: 'Bundle',
        bundle_case: 'Bundle case',
        deck: 'Deck',
        deck_box: 'Deck box',
        kit: 'Kit',
        limited_aid_case: 'Limited aid case',
        limited_aid_tool: 'limited aid tool',
        multiple_decks: 'Multiple decks',
        subset: 'Subset',
    }

    return mapCategoryToLabels[categoryKey] ?? categoryKey
}

export const PokemonProductSealedDetails = ({ product }) => {
    const imageUrl = product?.img || noImage
    const [logoUrl, setLogoUrl] = useState('')

    useEffect(() => {
        if (product.setCode) {
            const fetchLogo = async () => {
                try {
                    const response = await fetch(`https://api.pokemontcg.io/v2/sets/${product.setCode.toLowerCase()}?select=images`)
                    const data = await response.json()
                    if (data.data && data.data.images && data.data.images.logo) {
                        setLogoUrl(data.data.images.logo)
                    }
                } catch (error) {
                    console.error('Error fetching logo:', error)
                }
            }
            fetchLogo()
        }
    }, [product.setCode])

    return (
        <div className="row">
            <h3>{product.name}</h3>
            <div className="col-12 col-lg-4 mt-2">
                <img
                    src={imageUrl}
                    className="product-sealed-image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = noImage
                        return true
                    }}
                ></img>
            </div>
            <div className="col-12 col-lg-8 mt-1" style={{ fontWeight: '300' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <dl className="labeled row no-gutters m-0">
                        <dt className="col-5">
                            <Translation id="card_name" defaultMessage="Card Name" />:
                        </dt>
                        <dd className="col-7">{product.name}</dd>

                        <dt className="col-5">
                            <Translation id="category" defaultMessage="Category" />:
                        </dt>
                        <dd className="col-7">
                            <SealedProductCategory categoryKey={product.sealedProductCategory} />
                        </dd>
                        <dt className="col-5">
                            <Translation id="set" defaultMessage="set" />:
                        </dt>
                        <dd className="col-7">
                            {product.setName} {product.setCode && <img style={{ width: '20px' }} src={logoUrl} />}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}
