import ReactTooltip from 'react-tooltip';
import LocalizedLink from '../../global/LocalizedLink'
import Translation from '../../global/Translation'
import React from 'react'
import { RenderProductSingleProperties } from '../../product/renderProductSingleInfo/RenderProductSingleProperties'
import { RenderProductSingleActions } from '../../product/renderProductSingleInfo/RenderProductSingleActions'
import Button from '../../global/Button'
import noImage from '../../../assets/img/advanced-search/advanced-search.jpg'

export const RenderCollectionItemMobile = ({
                                         item,
                                         intl,
                                         isLogged,
                                         userName,
                                         isVerified,
                                         bankData,
                                         isLoading,
                                         updateQty,
                                         onShowUpLogin,
                                         isUserItemEqualToCurrentLogged,
                                         deleteCollectionItem
                                     }) => {

    return (
        <div key={item.id} className="card table-row">
            <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="img">
                    <LocalizedLink routeId="ROUTE_PRODUCT" params={{ id: item.product.id }}>
                        <img
                            src={item.product.img}
                            className="card-img-top"
                            data-tip
                            data-for={'min-' + item.product.id}
                            alt="..."
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = noImage
                                return true
                            }}
                        />
                        <ReactTooltip backgroundColor={'white'} id={'min-' + item.product.id} effect="solid">
                            <img alt="product" style={{ width: '256px' }} src={item.product.img}></img>
                        </ReactTooltip>
                    </LocalizedLink>
                </div>
                <div>
                    <LocalizedLink routeId="ROUTE_PRODUCT" params={{ id: item.product.id }}>
                        <h5 className="card-title">{item.product.name}</h5>
                    </LocalizedLink>
                </div>
            </div>
            <div className="card-body">
                <div className="properties" style={{ display: 'flex', flexDirection: 'row' }}>
                    <RenderProductSingleProperties original={item} showExpansion={true} />
                </div>
            </div>
            <div className="card-footer" style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                    <span>
                        <Translation id="qty" defaultMessage="Quantity" />
                    </span>
                    <strong>{item.qty}</strong>
                </div>
                <div className="actions">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <span>
                            {item.blocked && (
                                <span>
                                    {item.qtyBlocked} <Translation id="x_blocked_items" defaultMessage="Items are on someone cart" />
                                </span>
                            )}
                        </span>
                        <br />
                        <br />

                        {deleteCollectionItem && (
                            <Button icon="trash" secondary onClick={() => deleteCollectionItem(item.id, item)}>
                                <Translation id="Delete" defaultMessage="delete" />
                            </Button>
                        )}
                        <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} params={{ view: 'edit-item-collection', id: item.id }}>
                            <Button icon="edit">
                                <Translation id="Edit" defaultMessage="edit" />
                            </Button>
                        </LocalizedLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
