import React, { useState } from 'react'
import Modal from './Modal'
import Translation from './Translation'
import Button from './Button'
import clipboardCopy from 'clipboard-copy'

export const ShareLinkModal = ({ url, title, message }) => {
    const [showModal, setShowModal] = useState(false)

    const handleCopyToClipboard = () => {
        clipboardCopy(url);
    };

    const handleShareWhatsApp = () => {
        window.open(`https://api.whatsapp.com/send?text=${message} ${url}`);
    };

    const handleShareFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`);
    };

    return (
        <React.Fragment>
            <div>
                <Button className="bt-size-medium mb-1" onClick={() => setShowModal(true)} secondary>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <Translation id="share-public-profile" defaultMessage="Share my public profile" />
                        <span className="material-icons-outlined" style={{ color: 'white', fontSize: '16px' }}>
                            share
                        </span>
                    </span>
                </Button>
            </div>
            {showModal && (
                <Modal title={title} onClose={() => setShowModal(false)}>
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                handleCopyToClipboard()
                            }}
                        >
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Translation id="copy-link" defaultMessage="Copy link" />
                                <span className="material-icons-outlined" style={{ color: 'white', fontSize: '16px' }}>
                                    share
                                </span>
                            </span>
                        </Button><br/>
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                handleShareWhatsApp()
                            }}
                        >
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Translation id="share-link-by-whatsapp" defaultMessage="Share by Whatsapp" />
                                <span className="material-icons-outlined" style={{ color: 'white', fontSize: '16px' }}>
                                    share
                                </span>
                            </span>
                        </Button><br/>
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                handleShareFacebook()
                            }}
                        >
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Translation id="share-link-by-facebook" defaultMessage="Share by Facebook" />
                                <span className="material-icons-outlined" style={{ color: 'white', fontSize: '16px' }}>
                                    share
                                </span>
                            </span>
                        </Button>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    )
}
