export const initState = []

const toastNotifications = (state = initState, action) => {
    switch (action.type) {
        case 'SHOW_TOAST_NOTIFICATION': {
            return [ ...state, action.toastNotification ]
        }
        case 'REMOVE_TOAST_NOTIFICATION': {
            const i = state.findIndex(t => t.id === action.toastNotification.id);

            if (i === -1) {
                return state;
            }

            return [
                ...state.slice(0, i),
                ...state.slice(i + 1)
            ]
        }
        default: {
            return state;
        }
    }
}

export default toastNotifications;