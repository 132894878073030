export const filterItems = (items,filters,availableStatus) => {
    if (!items) {
        return []
    }
    let itemsFiltered = items

    if (filters.search) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return (
                row.product.name.toLowerCase().includes(filters.search.toLowerCase()) || row.product.setCode.toLowerCase().includes(filters.search.toLowerCase())
            )
        })
    }
    if (filters.selectedLang && filters.selectedLang.value !== null) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return row.language.includes(filters.selectedLang.value)
        })
    }
     if (filters.selectedExpansion && filters.selectedExpansion.value !== null) {
         itemsFiltered = itemsFiltered.filter((row) => {
            // console.log(row)
             return row.product.setName.includes(filters.selectedExpansion.value)
         })
     }
    if (filters.selectedCondition && filters.selectedCondition.value !== null) {
        const statusFiltered = availableStatus
            .filter((item) => {
                return item.numberValue >= filters.selectedCondition.numberValue
            })
            .map((item) => item.value)
        itemsFiltered = itemsFiltered.filter((row) => {
            return statusFiltered.includes(row.state)
        })
    }
    if (filters.priceMin) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return row.price >= filters.priceMin
        })
    }

    if (filters.priceMax) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return row.price <= filters.priceMax
        })
    }

    if (filters.comments) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return row.comments?.toLowerCase().includes(filters.comments.toLowerCase());
        })
    }

    if (filters.selectedRarity && filters.selectedRarity.value !== null) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return row.product.rarity === filters.selectedRarity.value;
        })
    }

    if (filters.isFoil.value !== null) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return Boolean(row.is_foil) === Boolean(filters.isFoil.value)
        })
    }
    if (filters.isSigned.value !== null) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return Boolean(row.is_signed) === Boolean(filters.isSigned.value)
        })
    }
    if (filters.isPlayset.value !== null) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return Boolean(row.is_playset) === Boolean(filters.isPlayset.value)
        })
    }
    if (filters.isAltered.value !== null) {
        itemsFiltered = itemsFiltered.filter((row) => {
            return Boolean(row.is_altered) === Boolean(filters.isAltered.value)
        })
    }
    return itemsFiltered
}
