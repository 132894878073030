import * as api from '../api'
import { getIsLogged, getToken, getVerified } from '../login/reducers'


export const fetchPendingTasks = () => (dispatch, getState) => {
    const isLogged = getIsLogged(getState())
    const isVerified = getVerified(getState())

    if (!isLogged || !isVerified) {
        return
    }

    dispatch({
        type: 'FETCH_PENDING_TASKS_REQUEST',
    })

    return api
        .fetchPendingTasks(getToken(getState()))
        .then((response) => {
            return dispatch({ type: 'FETCH_PENDING_TASKS_SUCCESS', pendingTasks: response.data.pendingTasks })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_PENDING_TASKS_FAILURE', response: error.response })
        })
}
