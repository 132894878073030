import Translation from '../../global/Translation'
import React, { useState } from 'react'
import noImage from '../../../assets/img/advanced-search/advanced-search.jpg'

export const ProductSingleDetails = ({ product, intl, goTo }) => {
    const [featureSelected, setFeatureSelected] = useState('ORACLE')

    const cardText = () => {
        if (featureSelected === 'PRINTED') {
            return product.originalText
        }
        return product.text
    }

    const cardType = () => {
        if (featureSelected === 'PRINTED') {
            return product.originalType
        }
        return product.type
    }

    const imageUrl = product?.img || noImage

    return (
        <div className="row">
            <h3>{product.name}</h3>
            <div className="col-12 col-lg-4 mt-2">
                <img
                    src={imageUrl}
                    className="card-image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = noImage
                        return true
                    }}
                ></img>
            </div>
            <div className="col-12 col-lg-8 mt-1" style={{ fontWeight: '300' }}>
                <ul className="nav nav-tabs mb-2">
                    <li className="nav-item">
                        <a
                            className={`nav-link ${featureSelected === 'ORACLE' ? 'active' : ''}`}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                setFeatureSelected('ORACLE')
                            }}
                        >
                            <strong style={{ fontWeight: 600 }}>Oracle</strong>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${featureSelected === 'PRINTED' ? 'active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setFeatureSelected('PRINTED')
                            }}
                            href="#"
                        >
                            <strong style={{ fontWeight: 600 }}>Printed</strong>
                        </a>
                    </li>
                </ul>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <dl className="labeled row no-gutters m-0">
                        <dt className="col-5">
                            <Translation id="card_name" defaultMessage="Card Name" />:
                        </dt>
                        <dd className="col-7">{product.name}</dd>

                        <dt className="col-5">
                            <Translation id="mana_cost" defaultMessage="Mana Cost" />:
                        </dt>
                        <dd className="col-7">{product.manaCost}</dd>

                        <dt className="col-5">
                            <Translation id="converted_mana_cost" defaultMessage="Converted Mana Cost" />:
                        </dt>
                        <dd className="col-7">{product.convertedManaCost}</dd>

                        <dt className="col-5">
                            <Translation id="types" defaultMessage="Types" />:
                        </dt>
                        <dd className="col-7">{cardType()}</dd>

                        <dt className="col-5">
                            <Translation id="card_text" defaultMessage="Card Text" />:
                        </dt>
                        <dd
                            className="col-7"
                            style={{
                                maxHeight: '110px',
                                overflowY: 'auto',
                            }}
                        >
                            {cardText()}
                        </dd>

                        {product.loyalty && (
                            <React.Fragment>
                                <dt className="col-5">
                                    <Translation id="loyalty" defaultMessage="Loyalty" />:
                                </dt>
                                <dd className="col-7">{product.loyalty}</dd>
                            </React.Fragment>
                        )}

                        {product.power && (
                            <React.Fragment>
                                <dt className="col-5">
                                    <Translation id="power" defaultMessage="Power" />:
                                </dt>
                                <dd className="col-7">{product.power}</dd>
                            </React.Fragment>
                        )}

                        {product.toughness && (
                            <React.Fragment>
                                <dt className="col-5">
                                    <Translation id="toughness" defaultMessage="Toughness" />:
                                </dt>
                                <dd className="col-7">{product.toughness}</dd>
                            </React.Fragment>
                        )}

                        <dt className="col-5">
                            <Translation id="rarity" defaultMessage="Rarity" />:
                        </dt>
                        <dd className="col-7">{product.rarity}</dd>

                        <dt className="col-5">
                            <Translation id="set" defaultMessage="set" />:
                        </dt>
                        <dd className="col-7">
                            {product.setName}{' '}
                            {product.keyruneCode && (
                                <img
                                    style={{ width: '20px' }}
                                    src={`https://svgs.scryfall.io/sets/${product.keyruneCode.toLowerCase()}.svg`}
                                    onError={(event) => {
                                        event.target.onerror = ''
                                        event.target.src = 'https://svgs.scryfall.io/sets/planeswalker.svg'
                                        return true
                                    }}
                                />
                            )}
                        </dd>

                        <dt className="col-5">
                            <Translation id="all_sets" defaultMessage="All Sets" />:
                        </dt>
                        <dd
                            className="col-7"
                            style={{ cursor: 'pointer', color: 'lightblue' }}
                            onClick={() => {
                                let url = intl.formatMessage({ id: 'ROUTE_HOME' })
                                url = url + '?q=' + product.originalName
                                goTo(url)
                            }}
                        >
                            <Translation id="reimpressions" defaultMessage="Show all versions" /> ({product.printings})
                        </dd>

                        <dt className="col-5">
                            <Translation id="card_number" defaultMessage="Card Number" />:
                        </dt>
                        <dd className="col-7">{product.number}</dd>

                        <dt className="col-5">
                            <Translation id="artist" defaultMessage="Artist" />:
                        </dt>
                        <dd className="col-7">{product.artist}</dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}
