import Translation from '../../global/Translation'
import React, { useState } from 'react'
import noImage from '../../../assets/img/advanced-search/advanced-search.jpg'

export const PokemonProductSingleDetails = ({ product, intl, goTo }) => {

    return (
        <div className="row">
            <h3>{product.name}</h3>
            <div className="col-12 col-lg-4 mt-2">
                <img
                    src={product.img}
                    className="card-image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = noImage
                        return true
                    }}
                ></img>
            </div>
            <div className="col-12 col-lg-8 mt-1" style={{ fontWeight: '300' }}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <dl className="labeled row no-gutters m-0">
                        <dt className="col-5">
                            <Translation id="card_name" defaultMessage="Card Name" />:
                        </dt>
                        <dd className="col-7">{product.name}</dd>

                        <dt className="col-5">
                            <Translation id="rarity" defaultMessage="Rarity" />:
                        </dt>
                        <dd className="col-7">{product.rarity}</dd>

                        <dt className="col-5">
                            <Translation id="set" defaultMessage="set" />:
                        </dt>
                        <dd className="col-7">
                            {product.setName}
                        </dd>

                        <dt className="col-5">
                            <Translation id="card_number" defaultMessage="Card Number" />:
                        </dt>
                        <dd className="col-7">{product.number}</dd>

                        <dt className="col-5">
                            <Translation id="artist" defaultMessage="Artist" />:
                        </dt>
                        <dd className="col-7">{product.artist}</dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}
