import React from 'react'
import Translation from '../global/Translation'

export const ShippingAddress = ({ address, showInCard = false }) => {

    const content = <ul className='list-group list-group-flush'>
        <li className='list-group-item'>
            <Translation id='receptor'
                         defaultMessage='Receptor' />: {address.full_name}{' '}
            {address.first_surname} {address.second_surname}
        </li>
        <li className='list-group-item'>
            <Translation id='address'
                         defaultMessage='Address' />: {address.address}
        </li>
        <li className='list-group-item'>
            <Translation id='city'
                         defaultMessage='City' />: {address.location}{' '}
            {address.zip_code}
        </li>
        <li className='list-group-item'>
            <Translation id='country'
                         defaultMessage='Country' />: {address.country?.name}
        </li>
    </ul>

    if (showInCard) {
        return <div className='card-body'>
            <h5 className='card-title'>
                <Translation id='shipping_address' defaultMessage='Shipping address' />
            </h5>
            {content}
        </div>
    }

    return content
}