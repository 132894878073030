import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Button from '../components/global/Button'
import Translation from '../components/global/Translation'
import LocalizedLink from '../components/global/LocalizedLink'
import { getUserName } from '../store/login/reducers'
import { SubheaderAlerts } from '../components/subheader/SubHeader'
import Spinner from '../components/global/Spinner'
import { verifyAccount } from '../store/login/actions'

defineMessages({
    CANNOT_VERIFY_DISTINCT_USER: {
        id: 'CANNOT_VERIFY_DISTINCT_USER',
        description: 'CANNOT_VERIFY_DISTINCT_USER',
        defaultMessage: 'You cannot verify user distinct of your user'
    },
    USER_VERIFY_EXPIRED_LINK: {
        id: 'USER_VERIFY_EXPIRED_LINK',
        description: 'USER_VERIFY_EXPIRED_LINK',
        defaultMessage: 'Expired link'
    }
})

const VerifyUserScreen = (props) => {
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(true)

    const userName = useSelector((state) => getUserName(state))

    useEffect(async () => {
        const params = new URLSearchParams(props.location.search)
        const url = params.get('token')

        if (!url) {
            props.history.push('/')
            return
        }

        dispatch(verifyAccount(url)).then(() => {
            setIsLoading(false)
        })
    }, [])

    if (isLoading) {
        return <Spinner isLoading={true}></Spinner>
    }

    return (
        <React.Fragment>
            <div>
                <div className='container-fluid registerForm mb-5' id='registerForm'>
                    <div className='q-top'>
                        <i className='fas fa-thumbs-up' style={{ padding: '0 15px' }}></i>
                        You did it, {userName}!
                    </div>
                    <div className='d-flex flex-column p-2'>
                        <p>
                            Welcome aboard, friend! Your email is verified, and you’re all set to jump into the
                            Cards & Collections world.
                        </p>
                        <p>
                            Time to unleash your inner collector and start exploring! We’re here if you need
                            anything, just let us know.
                        </p>

                        <h5 className='mt-4'>
                            Enjoy the ride, the adventure's just getting started!
                            <br /> C&C Team
                        </h5>

                        <div style={{ maxWidth: 450 }}>
                            <LocalizedLink routeId='ROUTE_HOME'>
                                <Button secondary={true}>
                                    <Translation id='TAKE_LOOK_CATALOG'
                                                 defaultMessage='Take a look to our catalog' />
                                </Button>
                            </LocalizedLink>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default withRouter(injectIntl(connect()(VerifyUserScreen)))
