/**
 * 
 * @param {string} text Texto que se mostrará en la notificación
 * @param {string} type Puede ser ["none", "success", "error", "warning" e "info"]. Adapta el estilo de la notificación según esto
 * @param {number} duration Tiempo durante el que se mostrará la notificación
 * @returns 
 */

export const showToastNotification = (text = '', type = 'none', duration = 10000) => dispatch => {
    const id = setTimeout(() => dispatch(removeToastNotification(id)), duration);
    dispatch({ type: 'SHOW_TOAST_NOTIFICATION', toastNotification: { id, text, type, duration } })
}

export const removeToastNotification = (id) => dispatch => {
    dispatch({ type: 'REMOVE_TOAST_NOTIFICATION', toastNotification: { id } })
}