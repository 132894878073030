import { RenderProductSingleProperties } from '../product/renderProductSingleInfo/RenderProductSingleProperties'
import React from 'react'

import { injectIntl } from 'react-intl'
import InputNumber from '../global/InputNumber'
import LocalizedLink from '../global/LocalizedLink'
import DisplayPrice from '../product/DisplayPrice'
import Translation from '../global/Translation'
import noImage from '../../assets/img/advanced-search/advanced-search.jpg'

class CartLine extends React.Component {
    constructor(props) {
        super(props)
        this.state = { showHelp: false, showComment: false }
    }

    render() {
        let { showSellerName = true, line, changeCartLineQty, onDelete, type, intl } = this.props
        if (!line || !line.lineId) return null

        return (
            <div className="card mb-2" key={line.lineId}>
                <div className="cart-line card-body line-item">
                    <div className="img">
                        <LocalizedLink className="cart_line__link_image" routeId="ROUTE_PRODUCT" params={{ id: line.productId }}>
                            <div className="image">
                                {' '}
                                {/* <ReactTooltip backgroundColor={'white'} id={'min-' + line.product.id} effect="solid">
                                    <img alt="product" style={{ width: '256px' }} src={line.product.img}></img>
                                </ReactTooltip> */}
                                <img alt="product"
                                     src={line.product.img}
                                     data-tip
                                     data-for={'min-' + line.product.id}
                                     onError={({ currentTarget }) => {
                                         currentTarget.onerror = null;
                                         currentTarget.src=noImage;
                                         return true;
                                     }}
                                />
                            </div>
                        </LocalizedLink>
                    </div>
                    <div className="content">
                        <div className="description-and-input">
                            <div className="mini_cart_line_title" style={{flexDirection:'column',alignItems:'start'}}>
                                <div className="title">
                                    <LocalizedLink routeId="ROUTE_PRODUCT" params={{ id: line.productId }}>
                                        <span className="title">{line.product.name}</span>
                                    </LocalizedLink>
                                </div>
                                <span className="card-text card-expansion" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {/* {product.setCode} */}
                                    {/* <ReactTooltip id={'min-set-' + product.id} effect="solid">
                            {product.setName}
                        </ReactTooltip> */}
                                    {line.product.keyruneCode &&<img
                                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                        src={`https://svgs.scryfall.io/sets/${line.product.keyruneCode.toLowerCase()}.svg`}
                                        // data-tip
                                        // data-for={'min-set-' + product.id}
                                        onError={(event) => {
                                            event.target.onerror = ''
                                            event.target.src = 'https://svgs.scryfall.io/sets/planeswalker.svg'
                                            return true
                                        }}
                                    />}
                                    {line.product.setName}
                                </span>
                            </div>

                            <div className="mini_cart_line_block" style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                {showSellerName && (
                                    <div>
                                        <Translation id="seller" defaultMessage="Seller"></Translation>: {line.item.sellerName}
                                    </div>
                                )}
                                <div className="mini_cart_line_price">
                                    <div className="mini_cart_line_info__line" style={{ display: 'block' }}>
                                        {/* No se va a usar hasta nueva orden */}
                                        {/* {line.unitPrice !== line.item.price && <span>ALERTA:EL VENDEDOR HA CAMBIADO EL PRECIO a {line.item.price}</span>} */}
                                        <DisplayPrice price={line.originalPrice} item={line.item}></DisplayPrice>
                                    </div>
                                </div>
                                <div className="cartline-actions">
                                    <InputNumber
                                        value={line.qty}
                                        change={(qty) => {
                                            changeCartLineQty(line.lineId, qty)
                                        }}
                                        size="small"
                                        step={1}
                                        //TODO::fix qty and include blocked_items by others users
                                        limit={line.unitPrice !== line.item.price ? line.qty : line.item.qty}
                                        // min={this.getMin(line)}
                                    />
                                    <div className="delete-button">
                                        <div>
                                            <span
                                                className="bt-delete"
                                                onClick={() => {
                                                    onDelete(line.lineId)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <i className="fal fa-trash-alt" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="mini_cart_line_block">
                                <div className="properties">
                                    <RenderProductSingleProperties original={line.item}></RenderProductSingleProperties>
                                </div>
                            </div>
                            {/* <div className="comment">
                            <div className={`info ${!this.state.showComment ? 'info--hidden' : 'p-2 my-1'}`}>
                                <input
                                    className="w-100"
                                    type="text"
                                    placeholder={this.props.intl.formatMessage({ id: 'comment' })}
                                    ref={input => {
                                        this.inputComment = input
                                    }}
                                    onChange={e => {
                                        this.props.changeCartLineComment(line.lineId, e.target.value)
                                    }}
                                    onKeyDown={() => this.saveComment()}
                                    onPaste={() => this.saveComment()}
                                    value={line.comment}
                                    maxlength="105"
                                />
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(CartLine)
