import React, { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../../../global/Translation'
import PersonalInformationForm from './PersonalInformationForm'
import ChangePasswordForm from './ChangePasswordForm'


const PersonalInformation = (props) => {
    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <h3>
                            <Translation id='user_info' defaultMessage='User Info' />
                        </h3>
                        <div className='card mb-3'>
                            <div className='card-body'>
                                <PersonalInformationForm />
                            </div>
                        </div>
                    </div>

                    {/*change password*/}
                    <div className='col-12 col-md-6'>
                        <h3>
                            <Translation id='change_password' defaultMessage='Change password' />
                        </h3>
                        <div className='card'>
                            <div className='card-body'>
                                <ChangePasswordForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(PersonalInformation)))
