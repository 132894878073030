import * as api from '../api'
import { getIsLogged, getToken, getVerified } from '../login/reducers'
import { push } from 'react-router-redux'


export const fetchChats = () => (dispatch, getState) => {
    const isLogged = getIsLogged(getState())
    const isVerified = getVerified(getState())

    if (!isLogged || !isVerified) {
        return
    }

    const pageSize = 100
    const page = 1

    const params = {
        page,
        'page-size': pageSize
    }
    dispatch({
        type: 'FETCH_CHATS_REQUEST',
        params
    })
    return api
        .fetchChats(getToken(getState()), params)
        .then((response) => {
            dispatch({
                type: 'FETCH_CHATS_SUCCESS',
                chats: response.data.data,
                pages: Math.ceil(response.data.total / response.data.per_page)
            })
        })
        .catch((error) => {
            dispatch({ type: 'FETCH_CHATS_FAILED', error: error.data })
        })
}

export const fetchChat = ({ chatId }) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_CHAT_MESSAGES_REKEST' })
    return api
        .fetchChat(chatId, getToken(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_CHAT_MESSAGES_SUCCESS', response, chatId })
            dispatch({ type: 'LOAD_COUNTER_MESSAGES_RESET' })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_CHAT_MESSAGES_FAILED', error: error.data })
        })
}

export const sendMessage = (chatId, message) => (dispatch, getState) => {
    dispatch({ type: 'SEND_CHAT_MESSAGE_REQUEST' })
    return api
        .sendMessage(message, chatId, getToken(getState()))
        .then(response => {
            dispatch({ type: 'SEND_CHAT_MESSAGE_SUCCESS', response })
            return dispatch(fetchChat({ chatId }))
            // dispatch(addCounter(chatId))
        })
        .catch(error => {
            dispatch({ type: 'SEND_CHAT_MESSAGE_FAILED', error })
        })
}

export const uploadAttachmentMessage = (chatId, attachment) => (dispatch, getState) => {
    dispatch({ type: 'SEND_ATTACHMENT_MESSAGE_CHAT_REQUEST' })
    return api
        .uploadAttachmentMessage(attachment, chatId, getToken(getState()))
        .then(response => {
            dispatch({ type: 'SEND_ATTACHMENT_MESSAGE_CHAT_SUCCESS', response })
            return dispatch(fetchChat({ chatId }))
        })
        .catch(error => {
            let errorMessage = 'The file you tried to upload is too large.'

            if (error.response && error.response.status === 422) {
                errorMessage = error.response.data.errors.file[0]
            }

            dispatch({ type: 'SEND_ATTACHMENT_MESSAGE_CHAT_FAILED', failureMessage: errorMessage })
        })
}

export const increaseUnreadMessageDistinctActiveChat = chatId => (dispatch, getState) => {
    dispatch({ type: 'INCREASE_UNREAD_DISTINCT_ACTIVE_CHAT_MESSAGES', chatId })
}

export const createChat = (userId1, userId2) => (dispatch, getState) => {
    dispatch({ type: 'GET_OR_CREATE_CHAT_REQUEST' })

    return api
        .createChat({
            userId1,
            userId2
        }, getToken(getState()))
        .then(respose => {
            dispatch({ type: 'GET_OR_CREATE_CHAT_SUCCESS' })
            // TODO: TRADUCIR RUTA
            dispatch(push('/dashboard/chats/' + respose.data.id))

            // if (respose.status === 201) dispatch(fetchChat({ chatId: respose.data.id}))
            // else if (respose.status === 200) {
            //     dispatch(fetchChat({ chatId: respose.data.chat_id }))
            // }
        })
        .catch(error => {
            dispatch({ type: 'GET_OR_CREATE_CHAT_FAILED', error })
            // dispatch(fetchChat(respose.data.id))
        })
}

export const clearCurrentChat = () => dispatch => {
    dispatch({ type: 'CLEAR_CURRENT_CHAT' })
}

export const clearChatUnreadCounter = (chatId, readMessagesId) => dispatch => {
    dispatch({ type: 'CLEAR_CHAT_UNREAD_COUNTER', chatId, readMessagesId })
}

