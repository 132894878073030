import { BoolMust, TermsQuery } from 'searchkit'
import store from '../../index'
import { getCurrentCollectableContext } from './reducers'

export const COLLECTABLE_CONTEXT_MTG = 'MTG'
export const COLLECTABLE_CONTEXT_LORCANA = 'LORCANA'
export const COLLECTABLE_CONTEXT_POKEMON = 'POKEMON'
export const COLLECTABLE_CONTEXT_ACCESSORY = 'ACCESSORY'

export const COLLECTABLE_CONTEXT_OPTIONS = [
    { label: 'Magic the gathering', value: COLLECTABLE_CONTEXT_MTG },
    { label: 'Lorcana', value: COLLECTABLE_CONTEXT_LORCANA },
    { label: 'Pokemon', value: COLLECTABLE_CONTEXT_POKEMON },
]

export const getCollectableContextOptionByContextValue = (contextValue) => COLLECTABLE_CONTEXT_OPTIONS.find((opt) => opt.value === contextValue)

export const setCollectableContextFilter = (sk, collectableContext) => {
    sk.addDefaultQuery((query) => {
        return query.addFilter('collectableContext', BoolMust(TermsQuery('collectableContext.raw', [collectableContext, COLLECTABLE_CONTEXT_ACCESSORY])))
    })
}

export const getDefaultProductCategoryByCurrentContext = () => {
    const currentCollectableContext = getCurrentCollectableContext(store.getState())

    const config = {
        [COLLECTABLE_CONTEXT_MTG]: {
            value: null,
            label: 'Any'
        },
        [COLLECTABLE_CONTEXT_LORCANA]: {
            value: null,
            label: 'Any'
        },
        [COLLECTABLE_CONTEXT_POKEMON]: {
            value: null,
            label: 'Single',
        },
    }

    return config[currentCollectableContext]
}
