import { push } from 'react-router-redux';
import * as api from '../api'
import { getToken, getUserId } from '../login/reducers'
import { getLanguage } from '../ui/reducers'
import { byProductId } from './reducers'



export const fetchProduct = (productId) => (dispatch, getState) => {
    const locale = getLanguage(getState())

    if (byProductId(getState(), productId)) return Promise.resolve()
    dispatch({
        type: 'FETCH_PRODUCT_REQUEST',
        productId,
        locale,
    })

    return api
        .fetchProductById(productId, locale)
        .then((response) => {
            return dispatch({ type: 'FETCH_PRODUCT_SUCCESS', product: response.data, locale })
        })
        .catch((error) => {
            api.errorHandler(dispatch, error.response, ['PRODUCT_NOT_FOUND']);
            return dispatch({ type: 'FETCH_PRODUCT_FAILURE', response: error.response });
        })
}

export const fetchProductSales = (productId) => (dispatch, getState) => {
    const locale = getLanguage(getState())  

    // if (fetchProductSales(getState(), productId)) return Promise.resolve()
    dispatch({
        type: 'FETCH_PRODUCT_SALES_REQUEST',
        productId,
        locale
        // page
    })

    return api
        .fetchProductSales(productId, locale)
        .then((response) => {
            // return dispatch({ type: 'FETCH_PRODUCT_SALES_SUCCESS', salesByProduct: response.data.data,saleItemsCurrentPage: response.data.current_page,saleItemsLastPage:response.data.last_page,productId, locale })
            return dispatch({
                type: 'FETCH_PRODUCT_SALES_SUCCESS',
                salesByProduct: response.data,
                productId,
                locale,
            })
        })
        .catch((error) => {
            console.error('fetchError', error)
            return dispatch({ type: 'FETCH_PRODUCT_SALES_FAILURE', response: error.response })
        })
}


export const fetchProductBuys = (productId) => (dispatch, getState) => {
    const locale = getLanguage(getState())

    // if (fetchProductSales(getState(), productId)) return Promise.resolve()
    dispatch({
        type: 'FETCH_PRODUCT_BUYS_REQUEST',
        productId,
        locale,
    })
    const userId = getUserId(getState())
    return api
        .fetchProductBuys(productId, locale, userId)
        .then((response) => {
            return dispatch({ type: 'FETCH_PRODUCT_BUYS_SUCCESS', buysByProduct: response.data, productId, locale })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_PRODUCT_BUYS_FAILURE', response: error.response })
        })
}


export const setCollectableContext = (context) => (dispatch, getState) => {
    dispatch({ type: 'SET_COLLECTABLE_CONTEXT', context })
    dispatch(push('/'))
}