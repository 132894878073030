import React from 'react'
import LocalizedLink from '../global/LocalizedLink'
import noImage from '../../assets/img/advanced-search/advanced-search.jpg'

export const WantToSellOrBuy = ({ type, itemsCount, productInfo, userToFilter,category }) => {
    let title = '';
    let view = 'sale';
    let itemsCountLabel = 'Sale items';
    if (type === 'SALE') {
        title = 'Want to sell';
        view = 'sale';
        itemsCountLabel = 'Sale items';
    } else if (type === 'BUY') {
        title = 'Want to buy';
        view = 'buy';
        itemsCountLabel = 'Buy items';
    }
    if(category){
        itemsCountLabel = category.replace('_',' ')
    }
    const image = productInfo?.img || noImage;
    return (
        <LocalizedLink routeId="ROUTE_USER_DASHBOARD" params={{ view, id: userToFilter }} className="col" queryString={`?filterCategory=${category}`}>
                {/* <h5 className='card-title col-centered'>{title}</h5> */}
                <div className='card-body d-flex flex-column justify-content-center align-items-center'
                     >
                        <div>
                            <img alt='product' style={{ width: '200px' }} src={image}></img>
                        </div>
                    <label className='col-centered' style={{textTransform:'capitalize'}}>{ itemsCountLabel } ({ itemsCount })</label>
                </div>
            
        </LocalizedLink>
    );
}
