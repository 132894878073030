import { PublicProfileLink } from '../global/PublicProfileLink'
import { RenderSaleItemMobile } from '../sale/list/RenderSaleItemMobile'
import React from 'react'
import Translation from '../global/Translation'
import Button from '../global/Button'
import DisplayPrice from '../product/DisplayPrice'
import logo from '../../logo-horizontal.svg'
import LocalizedLink from '../global/LocalizedLink'
import { getCheckoutUrlByCheckoutId } from '../orders/Order'
import Alerts from '../alerts/Alerts'
import { defineMessages } from 'react-intl'

defineMessages({
    UNPAID_ORDERS_INFO: {
        id: 'UNPAID_ORDERS_INFO',
        description: 'UNPAID_ORDERS_INFO',
        defaultMessage: 'You have not enough credit to confirm order',
    },
})

class OrderFinished extends React.Component {
    componentDidMount() {
        this.props.getCheckoutSummary(this.props.checkoutId)
        this.props.fetchCredit()
    }
    render() {
        const { show } = this.props
        // if (!reservationCode) {
        //     return null
        // }

        if(!this.props.checkoutResponse){
            return null
        }

        return (
            <div>
                <section className="container-fluid section invisible-section" data-cy="global__modal_thank_you" style={{backgroundColor:'unset',display:'flex',gap:'8px',flexDirection:'column',padding:'0px'}}>
                    <div className="row">
                        <div className="col-12 col-lg-6 logo-container d-none d-lg-block">
                            <img alt="" id="logo-img" className="m-2 mt-3" src={logo} />
                        </div>
                        <div className="col-12 col-lg-6 container">
                            <div className="row">
                                <div className="col-12 m-2">
                                    <h2>
                                        <div>
                                            <Translation id="order_finished_header" defaultMessage="Your order has been finished successfully" />{' '}
                                            {/* <span data-cy="order_finished__reservation_code">{reservationCode}</span> */}
                                        </div>
                                    </h2>
                                    <Translation id="order_finished_body" defaultMessage="Thank you for your order" />

                                    <div className="col-12 col-md-4 p-1" />
                                </div>
                            </div>
                            {/* <footer style={{ display: 'flex', flexDirection: '', alignItems: 'space-between' }}>
                                <div className="col-12">
                                    <LocalizedLink routeId="ROUTE_HOME">
                                        <Button datacy="global__keep_buying">
                                            <Translation id="keep_buying" defaultMessage="Keep Buying" />
                                        </Button>
                                    </LocalizedLink>
                                    <li>
                                        Quantity:{' '}
                                        <strong>
                                            <DisplayPrice price={Math.abs(this.props.credit)}></DisplayPrice>
                                        </strong>
                                    </li>

                                    {/* {Math.abs(this.props.credit) < 0 && ( */}

                            {/* )}
                                </div>
                            </footer> */}
                        </div>
                    </div>
                    <div className="d-flex checkout-summary" style={{gap:'8px'}}>
                        <div className="col-12 col-md-6 card">
                            <div className="card-body">
                                {this.props.checkoutResponse.type === 'SALE' && (
                                    <React.Fragment>
                                        <h5 className="card-title"><Translation id="shipping_address" defaultMessage="Shipping address" /></h5>
                                        {this.props.shippingAddress ? (
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item">
                                                    <Translation id="receptor" defaultMessage="Receptor" />: {this.props.shippingAddress.full_name} {this.props.shippingAddress.first_surname}{' '}
                                                    {this.props.shippingAddress.second_surname}
                                                </li>
                                                <li className="list-group-item"><Translation id="address" defaultMessage="Address" />: {this.props.shippingAddress.address}</li>
                                                <li className="list-group-item">
                                                    <Translation id="city" defaultMessage="City" />: {this.props.shippingAddress.location} - {this.props.shippingAddress.zip_code}
                                                </li>
                                                <li className="list-group-item"><Translation id="country" defaultMessage="Country" />: {this.props.shippingAddress.country.name}</li>
                                            </ul>
                                        ) : (
                                            <Button>
                                                <LocalizedLink routeId="ROUTE_USER_PROFILE" params={{ view: 'shipping-information' }}>
                                                    <Translation id="shipping_information" defaultMessage="Shipping information" />
                                                </LocalizedLink>
                                            </Button>
                                        )}
                                    </React.Fragment>
                                )}

                                {this.props.checkoutResponse.type === 'BUY' && (
                                    <React.Fragment>
                                        <Translation id="CART_SUMMARY_INSTRUCTIONS_TITLE" values={{ SELLER_NAME: this.props.userName }} />
                                        <ul>
                                            <li>
                                                <Translation id="CART_SUMMARY_INSTRUCTIONS_1" />
                                            </li>
                                            <li>
                                                <Translation id="CART_SUMMARY_INSTRUCTIONS_2" />
                                            </li>
                                            <li>
                                                <Translation id="CART_SUMMARY_INSTRUCTIONS_3" />
                                            </li>
                                        </ul>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 card">
                            <div className="card-body">
                                <h5 className="card-title">
                                    <Translation id="SUMMARY" defaultMessage="Summary" />
                                </h5>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <Translation id="ORDERS" defaultMessage="Orders" />: {this.props.checkoutResponse.orders.length}
                                    </li>
                                    <li className="list-group-item">
                                        <Translation id="ITEMS" defaultMessage="Items" />: {this.props.checkoutResponse.qty}
                                    </li>

                                    {this.props.checkoutResponse.type === 'SALE' && (
                                        <React.Fragment>
                                            <li className="list-group-item">
                                                <Translation id="ITEMS_COST" defaultMessage="Items cost" />: <DisplayPrice price={this.props.checkoutResponse.price} />
                                            </li>
                                            <li className="list-group-item">
                                                <Translation id="SHIPPING_TAXES_COST" defaultMessage="Shipping taxes cost" />:{' '}
                                                <DisplayPrice price={this.props.checkoutResponse.totalWithShippingTaxes - this.props.checkoutResponse.price} showZero={true} />
                                            </li>

                                            <div
                                                className={this.props.checkoutResponse.totalWithShippingTaxesWithDiscount ? 'list-group-item' : 'card-footer'}
                                                style={{ textDecoration: this.props.checkoutResponse.totalWithShippingTaxesWithDiscount ? 'line-through' : null }}
                                            >
                                                <Translation id="TOTAL_WITH_SHIPPING_TAXES_COST" defaultMessage="Total (with shipping taxes)" />:{' '}
                                                <DisplayPrice price={this.props.checkoutResponse.totalWithShippingTaxes} />
                                            </div>
                                            {this.props.checkoutResponse.totalWithShippingTaxesWithDiscount && (
                                                <React.Fragment>
                                                    <li className="list-group-item">
                                                        <Translation id="discount_applied" defaultMessage="Discount applied" />:{' '}
                                                        <DisplayPrice
                                                            price={
                                                                this.props.checkoutResponse.totalWithShippingTaxes - this.props.checkoutResponse.totalWithShippingTaxesWithDiscount
                                                            }
                                                        />
                                                    </li>
                                                    <div className="card-footer">
                                                        <Translation id="TOTAL_WITH_DISCOUNT" defaultMessage="Total (with discount)" />
                                                        : <DisplayPrice price={this.props.checkoutResponse.totalWithShippingTaxesWithDiscount} />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {this.props.checkoutResponse.type === 'BUY' && (
                                        <React.Fragment>
                                            <div className="card-footer">
                                                <Translation id="TOTAL_COST" defaultMessage="total cost" />:{' '}
                                                <DisplayPrice price={this.props.checkoutResponse.totalWithShippingTaxes} />
                                            </div>
                                        </React.Fragment>
                                    )}
                                </ul>

                                {!this.props.checkoutResponse.paidAt && this.props.checkoutResponse.type === 'SALE' && (
                                    <div style={{marginTop: 16}}>
                                         <Alerts
                                             alert='UNPAID_ORDERS_INFO'
                                             status='warning'>
                                         </Alerts>
                                        <Button
                                            onClick={() => this.props.payMyPendingCredit(getCheckoutUrlByCheckoutId(this.props.checkoutId))}
                                            datacy="global__keep_buying"
                                        >
                                            <span className='d-flex gap-2'>
                                                <Translation id='pay_now' defaultMessage='Pay now' />
                                                <strong>
                                                    (<DisplayPrice price={Math.abs(this.props.credit)}></DisplayPrice>)
                                                </strong>
                                            </span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className="card-list">
                            {this.props.checkoutResponse &&
                                this.props.checkoutResponse.orders.map((order) => {
                                    // return <h2>{order.head.seller_id}</h2>
                                    return (
                                        <React.Fragment>
                                            <div className="card" style={{flexWrap:'wrap',marginBottom:'8px'}}>
                                                <div className="card-body">
                                                    <h5 className="card-title">
                                                        <PublicProfileLink userNameSlug={order.head.seller.name_slug} label={order.head.seller.name} />
                                                        {/* <FlagIcon  code={order.sellerCountryCode.toLowerCase()} /> */}
                                                        <small
                                                            style={{ cursor: 'pointer', padding: '16px' }}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                this.openChat(order.head.seller.id)
                                                            }}
                                                        >
                                                            <i className="fa fa-paper-plane" /> Contact
                                                        </small>
                                                    </h5>
                                                    <div>
                                                        {order.lines.map((original) => (
                                                            <RenderSaleItemMobile key={original.id} item={original} intl={this.props.intl} showActions={false} />
                                                        ))}
                                                    </div>
                                                </div>
                                                <ul className="list-group list-group-flush" style={{flexBasis:'100%',borderBottomLeftRadius:'16px',borderBottomRightRadius:'16px'}}>
                                                    {order.head.type === 'SALE' && (
                                                        <React.Fragment>
                                                            {order.shipping_method_type}
                                                            <li className="list-group-item">
                                                                Items cost: <DisplayPrice price={order.head.total_withouth_shipping_taxes} />
                                                            </li>
                                                            <div
                                                                className={order.head.total_with_shipping_taxes_with_discount ? 'list-group-item' : 'list-group-item'}
                                                                style={{
                                                                    textDecoration: order.head.total_with_shipping_taxes_with_discount ? 'line-through' : null,
                                                                }}
                                                            >
                                                                <Translation id="TOTAL_WITH_SHIPPING_TAXES_COST" defaultMessage="Total (with shipping taxes)" />:{' '}
                                                                <DisplayPrice price={order.head.total_with_shipping_taxes} />
                                                            </div>
                                                            {order.head.total_with_shipping_taxes_with_discount && (
                                                                <React.Fragment>
                                                                    <li className="list-group-item">
                                                                        <Translation id="discount_applied" defaultMessage="Discount applied" />:{' '}
                                                                        <DisplayPrice
                                                                            price={order.head.total_with_shipping_taxes - order.head.total_with_shipping_taxes_with_discount}
                                                                        />
                                                                    </li>
                                                                    <div className="list-group-item">
                                                                        <Translation id="TOTAL_WITH_DISCOUNT" defaultMessage="Total (with discount)" />:{' '}
                                                                        <DisplayPrice price={order.head.total_with_shipping_taxes_with_discount} />
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                    {order.head.type === 'BUY' && (
                                                        <div className="list-group-item">
                                                            Total: <DisplayPrice price={order.head.total_with_shipping_taxes} />
                                                        </div>
                                                    )}
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default OrderFinished
