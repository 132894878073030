import React from 'react'
import Translation from '../global/Translation'
import ReactTooltip from 'react-tooltip'

export const PickupAddress = ({ address, showInCard = false, showInLine = false }) => {

    if (showInLine) {
        return <span className='d-flex align-items-center gap-2'>
            <span className='material-symbols-outlined' style={{ fontSize: '32px' }}>
                box
            </span>
            <span><Translation id='pickup_address' defaultMessage='Pick up address' />:</span>
            <span>{`${address.zip_code} - ${address.address} - ${address.location}`}</span>
        </span>
    }

    const content = <ul className='list-group list-group-flush'>
        <li className='list-group-item'>
            <Translation id='address'
                         defaultMessage='Address' />: {address.address}
        </li>
        <li className='list-group-item'>
            <Translation id='city'
                         defaultMessage='City' />: {address.location}{' '}
            {address.zip_code}
        </li>
        <li className='list-group-item'>
            <Translation id='country'
                         defaultMessage='Country' />: {address.country?.name}
        </li>
    </ul>

    if (showInCard) {
        return <div className='card-body'>
            <h5 className='card-title'>
                <Translation id='pickup_address' defaultMessage='Pick up address' />
            </h5>
            {content}
        </div>
    }

    return content



}