import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import Alerts from '../alerts/Alerts'
import Button from '../global/Button'
import CartSummary from './CartSummary'
import InputVoucher from './InputVoucher'
import confirm from '../global/confirm'
import DisplayPrice from '../product/DisplayPrice'
import { ShippingAddress } from '../address/ShippingAddress'

defineMessages({
    CART_SUMMARY_INSTRUCTIONS_TITLE: {
        id: 'CART_SUMMARY_INSTRUCTIONS_TITLE',
        description: 'CART_SUMMARY_INSTRUCTIONS_TITLE',
        defaultMessage: 'Dear {SELLER_NAME}, please remember the following:'
    },
    CART_SUMMARY_INSTRUCTIONS_1: {
        id: 'CART_SUMMARY_INSTRUCTIONS_1',
        description: 'CART_SUMMARY_INSTRUCTIONS_1',
        defaultMessage: 'Shipping costs will be at your expense. Choose the method that is safest for the buyer.'
    },
    CART_SUMMARY_INSTRUCTIONS_2: {
        id: 'CART_SUMMARY_INSTRUCTIONS_2',
        description: 'CART_SUMMARY_INSTRUCTIONS_2',
        defaultMessage: 'Pack the shipment correctly. Buyers will appreciate it.'
    },
    CART_SUMMARY_INSTRUCTIONS_3: {
        id: 'CART_SUMMARY_INSTRUCTIONS_3',
        description: 'CART_SUMMARY_INSTRUCTIONS_3',
        defaultMessage: "Look at the buyer's profile, they may have more items you are interested in!",
    },
    cart_with_pick_up_msg: {
        id: 'cart_with_pick_up_msg',
        description: 'cart_with_pick_up_msg',
        defaultMessage: 'Remember, for pick-up orders, head to the seller’s pick-up location provided below to retrieve your items.',
    }
})

class Cart extends React.Component {
    constructor(props) {
        super(props)
        this.state = { alert: null }
    }

    componentDidMount() {
        this.props.fetchCart(this.props.selectedCenter)
        if (!this.hasEnoughUserCredit()) {
            // this.setState({ alert: 'NOT_ENOUGH_CREDIT' })
        } else if (!this.props.shippingAddress) {
            this.setState({ alert: 'NOT_SHIPPING_ADDRESS' })
        } else {
            this.setState({ alert: null })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.totalWithShippingTaxes != prevProps.totalWithShippingTaxes) {
            if (!this.hasEnoughUserCredit()) {
                // this.setState({ alert: 'NOT_ENOUGH_CREDIT' })
            } else if (!this.props.shippingAddress) {
                this.setState({ alert: 'NOT_SHIPPING_ADDRESS' })
            } else {
                this.setState({ alert: null })
            }
        }
    }

    confirmEmptyCart = () => {
        confirm(this.props.intl.formatMessage({ id: 'CONFIRM' })).then(
            (confirm) => {
                this.props.emptyCart()
            },
            (cancel) => {
            }
        )
    }

    hasEnoughUserCredit = () => {
        if (this.props.cartType === 'BUY') {
            return true
        }
        return this.props.credit >= this.props.totalWithShippingTaxes
    }

    hasSomeOrderPickup = () => {
        return Object.values(this.props.linesBySellerFromBackend ?? {})?.some(bySeller => bySeller?.shippingMethod?.type === 'pickup')
    }

    render() {

        const {
            lines,
            totalQty,
            totalSellers,
            totalPrice,
            totalWithShippingTaxes,
            totalWithShippingTaxesWithDiscount,
            shippingAddress,
            userName
        } = this.props
        if (!lines) {
            return ''
        }

        return (
            <section class="container-fluid section invisible-section checkout" style={{backgroundColor:'unset',padding:'0px',display:'flex',flexDirection:'column',gap:'8px'}}>
                {this.props.checkoutResponse && this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_ORDER_FINISHED' }))}
                <div className="flex flex-col checkout-summary" style={{display:'flex',gap:'8px'}}>
                    <div className="card" style={{width:'100%'}}>
                        <div className="card-body d-flex flex-column">
                            {this.props.cartType === 'SALE' && (
                                <React.Fragment>
                                    <h5 className="card-title"><Translation id="shipping_address" defaultMessage="Shipping address" /></h5>
                                    {shippingAddress ? (
                                        <div className='d-flex flex-column justify-content-between flex-grow-1'>
                                            <ShippingAddress
                                                address={shippingAddress}
                                            />

                                            {this.hasSomeOrderPickup() &&
                                                <Alerts status="primary" alert='cart_with_pick_up_msg'></Alerts>
                                            }
                                        </div>
                                    ) : (
                                        <Button>
                                        <LocalizedLink routeId="ROUTE_USER_PROFILE" params={{ view: 'shipping-information' }}>
                                                <Translation id="shipping_information" defaultMessage="Shipping information" />
                                            </LocalizedLink>
                                        </Button>
                                    )}
                                </React.Fragment>
                            )}

                            {this.props.cartType === 'BUY' && (
                                <React.Fragment>
                                    <Translation id="CART_SUMMARY_INSTRUCTIONS_TITLE" values={{ SELLER_NAME: userName }} />
                                    <ul>
                                        <li>
                                            <Translation id="CART_SUMMARY_INSTRUCTIONS_1" />
                                        </li>
                                        <li>
                                            <Translation id="CART_SUMMARY_INSTRUCTIONS_2" />
                                        </li>
                                        <li>
                                            <Translation id="CART_SUMMARY_INSTRUCTIONS_3" />
                                        </li>
                                    </ul>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className="card" style={{width:'100%'}}>
                        <div className="card-body">
                            <h5 className="card-title">
                                <Translation id="SUMMARY" defaultMessage="Summary" />
                            </h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <Translation id="ORDERS" defaultMessage="Orders" />: {totalSellers}
                                </li>
                                <li className="list-group-item">
                                    <Translation id="ITEMS" defaultMessage="Items" />: {totalQty}
                                </li>

                                {this.props.cartType === 'SALE' && (
                                    <React.Fragment>
                                        <li className="list-group-item">
                                            <Translation id="ITEMS_COST" defaultMessage="Items cost" />: <DisplayPrice price={totalPrice} />
                                        </li>
                                        <li className="list-group-item">
                                            <Translation id="SHIPPING_TAXES_COST" defaultMessage="Shipping taxes cost" />:{' '}
                                            <DisplayPrice price={totalWithShippingTaxes - totalPrice} showZero={true} />
                                        </li>

                                        <div
                                            className={totalWithShippingTaxesWithDiscount ? 'list-group-item' : 'card-footer'}
                                            style={{ textDecoration: totalWithShippingTaxesWithDiscount ? 'line-through' : null }}
                                        >
                                            <Translation id="TOTAL_WITH_SHIPPING_TAXES_COST" defaultMessage="Total (with shipping taxes)" />:{' '}
                                            <DisplayPrice price={totalWithShippingTaxes} />
                                        </div>
                                        {totalWithShippingTaxesWithDiscount && (
                                            <React.Fragment>
                                                <li className="list-group-item">
                                                    <Translation id="discount_applied" defaultMessage="Discount applied" />:{' '}
                                                    <DisplayPrice price={totalWithShippingTaxes - totalWithShippingTaxesWithDiscount} />
                                                </li>
                                                <div className="card-footer">
                                                    <Translation id="TOTAL_WITH_DISCOUNT" defaultMessage="Total (with discount)" />:{' '}
                                                    <DisplayPrice price={totalWithShippingTaxesWithDiscount} />
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                                {this.props.cartType === 'BUY' && (
                                    <React.Fragment>
                                        <div className="card-footer">
                                            <Translation id="TOTAL_COST" defaultMessage="Total cost" />: <DisplayPrice price={totalWithShippingTaxes} />
                                        </div>
                                    </React.Fragment>
                                )}
                            </ul>
                            <Alerts alert={this.state.alert} status={400}></Alerts>

                            <InputVoucher />

                            <Button className='mt-4' disabled={this.props.isSending} onClick={() => this.props.checkout()}>
                                <Translation id="finish_order" defaultMessage="Finish order" />
                            </Button>
                        </div>
                    </div>
                </div>

                <CartSummary bySeller={true} changeShippingMethodBySeller={() => this.props.changeShippingMethodBySeller()} {...this.props} />
            </section>
        )
    }
}

export default injectIntl(Cart)
