import { getCollectionRarityAvailableList } from './../store/collection/reducers';
import { getSaleItemsRarityAvailableList } from './../store/sale/reducers';
import React from 'react'
import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import {
    getCollection,
    getCollectionExpansionsAvailableList,
    getCollectionTableInfo,
    getIsLoading
} from '../store/collection/reducers'
import { deleteItemFromCollection, fetchCollections, updateQtyItemFromCollection } from '../store/collection/actions'
import Button from '../components/global/Button'
import confirm from '../components/global/confirm'
import LocalizedLink from '../components/global/LocalizedLink'
import Select from 'react-select'
import { getBooleanOptions, getRarityOptions, getAvailableLangsIncludingAny, getAvailableStatusIncludingAny } from '../store/products/reducers'
import { showLoginModal } from '../store/ui/actions'
import { getBankData, getIsLogged, getUserId, getUserName, getVerified } from '../store/login/reducers'
import { RenderCollectionItemMobile } from '../components/collection/list/RenderCollectionItemMobile'
import Modal from '../components/global/Modal'
import debounce from 'lodash/debounce'
import InfiniteScroll from 'react-infinite-scroll-component'

defineMessages({
    TOKEN_HAS_EXPIRED: {
        id: 'MY_COLLECTION_LIST',
        description: 'MY_COLLECTION_LIST',
        defaultMessage: 'My collection list'
    },
    graded: {
        id: 'graded',
        description: 'graded',
        defaultMessage: 'graded'
    },
    actions: {
        id: 'actions',
        description: 'actions',
        defaultMessage: 'Actions'
    }
})


const mapStateToProps = (state) => {
    return {
        isLoading: getIsLoading(state),
        collectionItems: getCollection(state),
        collectionTableInfo: getCollectionTableInfo(state),
        availableLangs: getAvailableLangsIncludingAny(state),
        availableStatus: getAvailableStatusIncludingAny(state),
        booleanOptions: getBooleanOptions(state),
        expansionOptions: getCollectionExpansionsAvailableList(state), //getExpansionOptionsIncludingAny(state),
        rarityOptions: getCollectionRarityAvailableList(state),
        bankData: getBankData(state),
        userIdLogged: getUserId(state),
        userName: getUserName(state),
        isLogged: getIsLogged(state),
        verified: getVerified(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCollection: (newPage, pageSize, filters) => {
            dispatch(fetchCollections(newPage, pageSize, filters))
        },
        deleteItemFromCollection: (itemId) => {
            return dispatch(deleteItemFromCollection(itemId))
        },
        updateQtyItemFromCollection: (itemId, qty) => {
            return dispatch(updateQtyItemFromCollection(itemId, qty))
        },
        onShowUpLogin: () => {
            dispatch(showLoginModal())
        }
    }
}
const confirmDeleteItem = (itemId, item, onDeleteItem, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_DELETE_ITEM_FROM_COLLECTION' })).then(
        (confirm) => {
            return onDeleteItem(itemId)
        },
        (cancel) => {
            return Promise.reject()
        }
    )
}

const defaultFiltersValue = {
    search: '',
    comments: '',
    selectedExpansion: null,
    selectedLang: null,
    selectedCondition: null,
    selectedRarity: {
        value: null,
        label: 'Any',
    },
    isFoil: {
        value: null,
        label: 'Any',
    },
    isSigned: {
        value: null,
        label: 'Any',
    },
    isPlayset: {
        value: null,
        label: 'Any',
    },
    isAltered: {
        value: null,
        label: 'Any',
    },
}


class CollectionScreen extends React.Component {
    defaultState = {
        filters: { ...defaultFiltersValue }
    }

    state = { ...this.defaultState }

    constructor() {
        super()
        this.searchCollections = debounce(this.searchCollections.bind(this), 400)
    }

    componentDidMount() {
        this.props.fetchCollection()
    }

    isUserListEqualToCurrentLogged() {
        //Dont put triple equal on this line
        return this.props.match.params.id ? this.props.match.params.id == this.props.userIdLogged : true
    }

    isUserItemEqualToCurrentLogged(item) {
        return this.props.userIdLogged == item.user_id
    }

    searchCollections(page = 1, pageSize = 10) {
        let filters = {}
        Object.entries(this.state.filters).forEach(([key, value]) => {
            if (value) {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    value = value.value
                }
                filters[key] = value
            }
        })
        this.props.fetchCollection(page, pageSize, filters)
    }

    renderFilters = () => {

        return (
            <form className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-3 col-xl-2">
                        <label htmlFor="name" className="form-label">
                            <Translation id="name" defaultMessage="Name" />
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder={this.props.intl.formatMessage({ id: 'SEARCH_BY_NAME_OR_WHATEVER' })}
                            value={this.state.filters.search}
                            autoComplete="off"
                            onChange={(e) =>
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            search: e.target.value,
                                        },
                                    },
                                    () => this.searchCollections()
                                )
                            }
                        />
                    </div>

                    <div className="col-12 col-lg-3 col-xl-2">
                        <label htmlFor="expansion" className="form-label">
                            <Translation id="expansion" defaultMessage="Expansion" />
                        </label>
                        <Select
                            options={
                                this.props.expansionOptions
                                // groupBySetName &&
                                // Object.keys(groupBySetName).map((option) => ({
                                //     label: option + ' (' + groupBySetName[option].id.length + ')',
                                //     value: option,
                                // }))
                            }
                            value={this.state.filters.selectedExpansion}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            selectedExpansion: selectedOption,
                                        },
                                    },
                                    () => this.searchCollections()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-2">
                        <label htmlFor="rarity" className="form-label">
                            <Translation id="rarity" defaultMessage="Rarity" />
                        </label>
                        <Select
                            options={
                                this.props.rarityOptions
                                // groupBySetName &&
                                // Object.keys(groupBySetName).map((option) => ({
                                //     label: option + ' (' + groupBySetName[option].id.length + ')',
                                //     value: option,
                                // }))
                            }
                            value={this.state.filters.selectedRarity}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            selectedRarity: selectedOption,
                                        },
                                    },
                                    () => this.searchCollections()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-1">
                        <label htmlFor="qty" className="form-label">
                            <Translation id="language" defaultMessage="Language" />
                        </label>
                        <Select
                            className=""
                            options={
                                this.props.availableLangs
                                // groupByLanguage &&
                                // Object.keys(groupByLanguage).map((option) => ({
                                //     label: option + ' (' + groupByLanguage[option].id.length + ')',
                                //     value: option,
                                // }))
                            }
                            value={this.state.filters.selectedLang}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            selectedLang: selectedOption,
                                        },
                                    },
                                    () => this.searchCollections()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-2">
                        <label htmlFor="qty" className="form-label">
                            <Translation id="condition" defaultMessage="Condition" />
                        </label>
                        <Select
                            className=""
                            options={this.props.availableStatus}
                            value={this.state.filters.selectedCondition}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            selectedCondition: selectedOption,
                                        },
                                    },
                                    () => this.searchCollections()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>

                    <div className="col-12 col-lg-2 col-xl-1">
                        <label htmlFor="foil" className="form-label">
                            <Translation id="foil" defaultMessage="Foil" />
                        </label>
                        <Select
                            className=""
                            options={this.props.booleanOptions}
                            value={this.state.filters.isFoil}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            isFoil: selectedOption,
                                        },
                                    },
                                    () => this.searchCollections()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-2 col-xl-1">
                        <label htmlFor="signed" className="form-label">
                            <Translation id="signed" defaultMessage="Signed" />
                        </label>
                        <Select
                            className=""
                            options={this.props.booleanOptions}
                            value={this.state.filters.isSigned}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            isSigned: selectedOption,
                                        },
                                    },
                                    () => this.searchCollections()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-2 col-xl-1">
                        <label htmlFor="playset" className="form-label">
                            <Translation id="playset" defaultMessage="Playset" />
                        </label>
                        <Select
                            className=""
                            options={this.props.booleanOptions}
                            value={this.state.filters.isPlayset}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            isPlayset: selectedOption,
                                        },
                                    },
                                    () => this.searchCollections()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-2 col-xl-1">
                        <label htmlFor="isAltered" className="form-label">
                            <Translation id="altered" defaultMessage="Altered" />
                        </label>
                        <Select
                            className=""
                            options={this.props.booleanOptions}
                            value={this.state.filters.isAltered}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            isAltered: selectedOption,
                                        },
                                    },
                                    () => this.searchCollections()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select',
                                }) + '...'
                            }
                        />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-1">
                        <label htmlFor="clear" className="form-label">
                            {/* <Translation id="clear_the_filters" defaultMessage="Empty" /> */}
                            &nbsp;
                        </label>
                        <Button
                            icon="trash"
                            secondary
                            onClick={() =>
                                this.setState(
                                    {
                                        filters: {
                                            ...defaultFiltersValue,
                                        },
                                    },
                                    () => this.searchCollections(1, 10)
                                )
                            }
                        >
                            <Translation id="clear" defaultMessage="Clear" />
                        </Button>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        let collectionItemsFiltered = this.props.collectionItems ?? []

        return (
            <div>
                <div className="container-fluid">
                    <div className="row" style={{ justifyContent: 'space-between',alignItems:'center' }}>
                        <div className="col-12 col-md-4">
                            <h2>
                                <Translation id="MY_COLLECTION_LIST" />
                            </h2>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2">
                            <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} params={{ view: 'add-to-collection', id: '' }}>
                                <Button icon="plus-circle">
                                    <Translation id="NEW_ITEM" defaultMessage="New item" />
                                </Button>
                            </LocalizedLink>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <h4 className="card-title">
                        <Translation id="filters" defaultMessage="Filters" />
                    </h4>
                    <div className='mt-3 mb-3'>
                        <div className='d-none d-lg-block'>{this.renderFilters()}</div>
                        <div className='d-lg-none row'>
                            <div className='col-8'>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    placeholder={this.props.intl.formatMessage({ id: 'SEARCH_BY_NAME_OR_WHATEVER' })}
                                    value={this.state.filters.search}
                                    autoComplete='off'
                                    onChange={(e) =>
                                        this.setState(
                                            {
                                                filters: {
                                                    ...this.state.filters,
                                                    search: e.target.value,
                                                },
                                            },
                                            () => this.searchCollections()
                                        )
                                    }
                                />
                            </div>

                            <div className='col-4'>
                                <Button onClick={() => this.setState({ showFilters: true })}>
                                    <span className='material-icons-outlined' style={{ fontSize: '32px' }}>
                                        filter_alt
                                    </span>
                                </Button>
                            </div>
                            {this.state.showFilters && (
                                <Modal title={<Translation id='filters' defaultMessage='Filters' />}
                                       onClose={() => this.setState({ showFilters: false })}>
                                    {this.renderFilters()}
                                </Modal>
                            )}
                        </div>
                    </div>

                    <div className="card-list">
                        <InfiniteScroll
                            dataLength={collectionItemsFiltered.length}
                            next={() => this.props.collectionTableInfo.page < this.props.collectionTableInfo.pagesCount && this.searchCollections(this.props.collectionTableInfo.page + 1, 10)}
                            hasMore={this.props.collectionTableInfo.page < this.props.collectionTableInfo.pagesCount}
                            loader={<h4>Loading...</h4>}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>You have seen it all</b>
                                </p>
                            }
                        >
                            {collectionItemsFiltered.map((item) => {
                                return (
                                    <RenderCollectionItemMobile
                                        key={item.id}
                                        item={item}
                                        intl={this.props.intl}
                                        onShowUpLogin={this.props.onShowUpLogin}
                                        isLogged={this.props.isLogged}
                                        userName={this.props.userName}
                                        bankData={this.props.bankData}
                                        isVerified={this.props.verified}
                                        isUserItemEqualToCurrentLogged={this.isUserListEqualToCurrentLogged(item)}
                                        updateQty={(id, qty) => {
                                            this.props.updateQtyItemFromCollection(id, qty).then(() => this.searchCollections())
                                        }}
                                        deleteCollectionItem={(id, original) => confirmDeleteItem(id, original, this.props.deleteItemFromCollection, this.props.intl).then(() => this.searchCollections())}
                                    />
                                )
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CollectionScreen)))
