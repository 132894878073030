import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import FinancialInformationContainer from './FinancialInformationContainer'
import Alerts from '../../../../components/alerts/Alerts'


const FinancialInformation = (props) => {

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <Alerts
                        status="info"
                        alert="At Cards & Collections, we ask for your bank account number to verify your identity as a seller. This helps us protect buyers by ensuring that all sellers on our platform are legitimate. Your information is only used for this purpose and to securely deposit your earnings from sales"
                    ></Alerts>
                    <FinancialInformationContainer />
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(FinancialInformation)))
