import React from 'react'

import LocalizedLink from '../global/LocalizedLink'
import Translation from '../global/Translation'

export default class Menu extends React.Component {
    constructor(props) {
        super(props)
        // this.state = {showMenu:false}
    }

    render() {
        const { infoDev } = this.props
        let input
        const handleSubmit = (event) => {
            const url = this.props.intl.formatMessage({ id: 'ROUTE_HOME' })
            event.preventDefault()
            this.props.onSubmit(url + '?q=' + input.value)
            input.value = ''
        }

        return (
            <div className=''>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {!this.props.isLogged && (
                        <div
                            style={{
                                flexDirection: 'column',
                                display: 'flex',
                                marginRight: '16px',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            id='menu-doll'
                            className='cart d-md-none'
                            onClick={() => {
                                this.props.onShowUpLogin()
                                // this.setState({ showMenu: !this.state.showMenu })
                            }}
                        >
                            <span className='material-icons-outlined' style={{ fontSize: '36px' }}>
                                login
                            </span>
                            Login
                        </div>
                    )}

                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        id='menu-doll'
                        className='cart d-md-none'
                        onClick={() => {
                            this.props.onToggleMenu(this.props.showMenu)
                            // this.setState({ showMenu: !this.state.showMenu })
                        }}
                    >
                        <span className='material-icons-outlined' style={{ fontSize: '36px' }}>
                            menu
                        </span>
                        Menu
                    </div>
                </div>
                {/* {this.state.showMenu && ( */}
                <div className={`menu d-md-block ${this.props.showMenu ? 'd-block' : 'd-none'}`}>
                    <div className='container-fluid'>
                        <nav className='navbar navbar-expand'>
                            <button
                                className='navbar-toggler'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#navbarSupportedContent'
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                            >
                                <span className='navbar-toggler-icon'></span>
                            </button>

                            <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                                <ul className='navbar-nav mr-auto'>
                                    <li className='nav-item active'>
                                        <div className='nav-link' href='#'>
                                            <LocalizedLink routeId='ROUTE_HOME'>
                                                <span className='material-icons-outlined' style={{ fontSize: '32px' }}>
                                                    home
                                                </span>
                                                <Translation id='home' defaultMessage='Home' />
                                            </LocalizedLink>
                                        </div>
                                    </li>
                                    <li className='nav-item active'>
                                        <div className='nav-link' href='#'>
                                            <LocalizedLink routeId='ROUTE_ADVANCED_SEARCH'>
                                                <span className='material-icons-outlined' style={{ fontSize: '32px' }}>
                                                    search
                                                </span>
                                                <Translation id='search' defaultMessage='Search' />
                                            </LocalizedLink>
                                        </div>
                                    </li>
                                    {this.props.isLogged && (
                                        <React.Fragment>
                                            {/*<li className='nav-item active'>*/}
                                            {/*    <div className='nav-link' href='#'>*/}
                                            {/*        <LocalizedLink routeId='ROUTE_USER_DASHBOARD'*/}
                                            {/*                       params={{ view: '', id: '' }}>*/}
                                            {/*            <span className='material-icons-outlined'*/}
                                            {/*                  style={{ fontSize: '32px' }}>*/}
                                            {/*                dashboard*/}
                                            {/*            </span>*/}
                                            {/*            <Translation id='CONTROL_PANEL' defaultMessage='Dashboard' />*/}
                                            {/*        </LocalizedLink>*/}
                                            {/*    </div>*/}
                                            {/*</li>*/}
                                            {/*

                                            <li className='nav-item dropdown'>
                                                <div
                                                    className='nav-link dropdown-toggle'
                                                    href='#'
                                                    id='navbarDropdown'
                                                    role='button'
                                                    data-bs-toggle='dropdown'
                                                    aria-haspopup='true'
                                                    aria-expanded='false'
                                                >
                                                    <span className='material-icons-outlined'
                                                          style={{ fontSize: '32px' }}>
                                                        person
                                                    </span>
                                                    <Translation id='profile' defaultMessage='Profile' />
                                                </div>

                                                <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                                    <LocalizedLink routeId='ROUTE_USER_PROFILE'
                                                                   params={{ view: 'personal-information' }}
                                                                   className='dropdown-item'>
                                                        <Translation id='personal_information'
                                                                     defaultMessage='Personal information' />
                                                    </LocalizedLink>
                                                    <LocalizedLink routeId='ROUTE_USER_PROFILE'
                                                                   params={{ view: 'shipping-information' }}
                                                                   className='dropdown-item'>
                                                        <Translation id='shipping_information'
                                                                     defaultMessage='Shipping information' />
                                                    </LocalizedLink>
                                                    <LocalizedLink routeId='ROUTE_USER_PROFILE'
                                                                   params={{ view: 'financial-information' }}
                                                                   className='dropdown-item'>
                                                        <Translation id='financial_information'
                                                                     defaultMessage='Financial information' />
                                                    </LocalizedLink>
                                                    <PublicProfileLink userId={this.props.userId}
                                                                       label='Mi perfil público'
                                                                       className='dropdown-item' />
                                                </div>
                                            </li>

*/}

                                            <li className='nav-item'>
                                                <div className='nav-link'>
                                                    <LocalizedLink routeId='ROUTE_USER_PROFILE'
                                                                   params={{ view: '' }}>
                                                        <span className='material-icons-outlined'
                                                              style={{ fontSize: '32px' }}>
                                                            person
                                                        </span>
                                                        <Translation id='profile' defaultMessage='Profile' />
                                                    </LocalizedLink>
                                                </div>
                                            </li>

                                            <li className='nav-item'>
                                                <div className='nav-link'>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'collection', id: '' }}>
                                                        <span className='material-icons-outlined'
                                                              style={{ fontSize: '32px' }}>
                                                            style
                                                        </span>
                                                        <Translation id='collection' defaultMessage='Collection' />
                                                    </LocalizedLink>
                                                </div>
                                            </li>

                                            <li className='nav-item'>
                                                <div className='nav-link'>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'sale', id: '' }}>
                                                        <span className='material-icons-outlined'
                                                              style={{ fontSize: '32px' }}>
                                                            currency_exchange
                                                        </span>
                                                        <Translation id='want_to_sell' defaultMessage='Want to sell' />
                                                    </LocalizedLink>
                                                </div>
                                            </li>

                                            <li className='nav-item'>
                                                <div className='nav-link'>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'buy', id: '' }}>
                                                        <span className='material-icons-outlined'
                                                              style={{ fontSize: '32px' }}>
                                                            shop
                                                        </span>
                                                        <Translation id='want_to_buy' defaultMessage='Want to buy' />
                                                    </LocalizedLink>
                                                </div>
                                            </li>

                                            <li className='nav-item'>
                                                <div className='nav-link'>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'transactions', id: '' }}>
                                                        <span className='material-icons-outlined'
                                                              style={{ fontSize: '32px' }}>
                                                            swap_horiz
                                                        </span>
                                                        <Translation id='order_history' defaultMessage='Order history' />
                                                    </LocalizedLink>
                                                </div>
                                            </li>

                                            {/*
                                            <li className='nav-item dropdown'>
                                                <div
                                                    className='nav-link dropdown-toggle'
                                                    id='navbarDropdown'
                                                    role='button'
                                                    data-bs-toggle='dropdown'
                                                    aria-haspopup='true'
                                                    aria-expanded='false'
                                                >
                                                    <span className='material-icons-outlined'
                                                          style={{ fontSize: '32px' }}>
                                                        style
                                                    </span>
                                                    <Translation id='collection' defaultMessage='Collection' />
                                                </div>
                                                <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'add-to-collection', id: '' }}
                                                                   className='dropdown-item'>
                                                        Add to collection
                                                    </LocalizedLink>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'collection', id: '' }}
                                                                   className='dropdown-item'>
                                                        List
                                                    </LocalizedLink>
                                                </div>
                                            </li>
*/}
                                            {/*
                                            <li className='nav-item dropdown'>
                                                <div
                                                    className='nav-link dropdown-toggle'
                                                    id='navbarDropdown'
                                                    role='button'
                                                    data-bs-toggle='dropdown'
                                                    aria-haspopup='true'
                                                    aria-expanded='false'
                                                >
                                                    <span className='material-icons-outlined'
                                                          style={{ fontSize: '32px' }}>
                                                        currency_exchange
                                                    </span>
                                                    <Translation id='want_to_sell' defaultMessage='Want to sell' />
                                                </div>
                                                <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'add-to-sale', id: '' }}
                                                                   className='dropdown-item'>
                                                        Add to sale
                                                    </LocalizedLink>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'sale', id: '' }}
                                                                   className='dropdown-item'>
                                                        List
                                                    </LocalizedLink>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'sales-list', id: '' }}
                                                                   className='dropdown-item'>
                                                        Transactions
                                                    </LocalizedLink>
                                                </div>
                                            </li>
*/}
                                            {/*
                                            <li className='nav-item dropdown'>
                                                <div
                                                    className='nav-link dropdown-toggle'
                                                    id='navbarDropdown'
                                                    role='button'
                                                    data-bs-toggle='dropdown'
                                                    aria-haspopup='true'
                                                    aria-expanded='false'
                                                >
                                                    <span className='material-icons-outlined'
                                                          style={{ fontSize: '32px' }}>
                                                        shop
                                                    </span>
                                                    <Translation id='want_to_buy' defaultMessage='Want to buy' />
                                                </div>
                                                <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'add-to-buy', id: '' }}
                                                                   className='dropdown-item'>
                                                        Add to buy
                                                    </LocalizedLink>

                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'buy', id: '' }}
                                                                   className='dropdown-item'>
                                                        List
                                                    </LocalizedLink>
                                                    <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                                   params={{ view: 'buys-list', id: '' }}
                                                                   className='dropdown-item'>
                                                        Transactions
                                                    </LocalizedLink>
                                                </div>
                                            </li>
*/}
                                            <li className='nav-item'>
                                                <LocalizedLink routeId='ROUTE_USER_DASHBOARD'
                                                               params={{ view: 'chats', id: '' }} className='nav-link'>
                                                    <span className='material-icons-outlined'
                                                          style={{ fontSize: '32px' }}>
                                                        chat
                                                    </span>
                                                    Chat
                                                </LocalizedLink>
                                                {this.props.unreadMessagesCount > 0 && (
                                                    <span
                                                        className='badge rounded-pill bg-danger'
                                                        style={{
                                                            color: 'white',
                                                            padding: '4px 8px',
                                                            position: 'relative',
                                                            top: '-36px',
                                                            right: '-24px'
                                                        }}
                                                    >
                                                        {this.props.unreadMessagesCount}
                                                    </span>
                                                )}
                                            </li>
                                        </React.Fragment>
                                    )}
                                    <li className='nav-item active'>
                                        <div className='nav-link' href='#'>
                                            <LocalizedLink routeId={'ROUTE_STATIC_PAGE'} params={{ view: 'faq'}}>
                                                <span className='material-icons-outlined' style={{ fontSize: '32px' }}>
                                                    contact_support
                                                </span>
                                                <Translation id='FAQS'
                                                             defaultMessage='FAQs' />
                                            </LocalizedLink>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                {/*  )} */}
            </div>
        )
    }
}
