import { PublicProfileLink } from './../global/PublicProfileLink';
import React from 'react'
import Spinner from '../global/Spinner'
import Translation from '../global/Translation'
import Button from '../global/Button'
import ReactTable from 'react-table-6'
import DisplayPrice from '../product/DisplayPrice'
import { injectIntl } from 'react-intl'
import moment from 'moment'

class Orders extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ordersFiltered: null }
    }

    componentDidMount = () => {
       this.fetchOrdersByType()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.type !== this.props.type) {
            this.fetchOrdersByType()
        }
    }

    fetchOrdersByType() {
        this.setState({ordersFiltered: null})
        if (this.props.type === 'BUY') {
            this.props.fetchBuys()
        } else {
            this.props.fetchSales()
        }
    }

    filterOrders = (status) => {
        let orders = this.props.orders.filter(item => {
            return status.includes(item.status)
        })
        // console.log(orders)
        this.setState({ ordersFiltered: orders })

    }

    render() {
        // console.log(this.props.product)
        if (!this.props.orders) return <Spinner isLoading={true}></Spinner>
        let ordersToMap = this.state.ordersFiltered || this.props.orders
        return (
            <div className="" id="product-card">
                {/* <GoBack></GoBack> */}
                <nav>
                    <div className={`nav nav-tabs mb-3 d-none d-lg-flex`} id="nav-tab" role="tablist" style={{ borderBottom: '0' }}>
                        {/* PENDING_CONFIRM CONFIRMED NOT_CONFIRMED CANCELLED CANCEL_FINISHED SEND RECEIVED LOST FINISHED PAID */}
                        <button
                            className="nav-link"
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                            onClick={() => this.setState({ ordersFiltered: this.props.orders })}
                        >
                            All <sup>{this.props.orders.length}</sup>
                        </button>
                        <button
                            className="nav-link"
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                            onClick={() => this.filterOrders(['UNPAID'])}
                        >
                            Unpaid <sup>{this.props.orders.filter((item) => ['UNPAID'].includes(item.status)).length}</sup>
                        </button>
                        <button
                            className="nav-link"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                            onClick={() => this.filterOrders(['PENDING_CONFIRM', 'CONFIRMED', 'PAID'])}
                        >
                            Paid <sup>{this.props.orders.filter((item) => ['PENDING_CONFIRM', 'CONFIRMED', 'PAID'].includes(item.status)).length}</sup>
                        </button>
                        <button
                            className="nav-link"
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                            onClick={() => this.filterOrders(['SEND'])}
                        >
                            Send <sup>{this.props.orders.filter((item) => ['SEND'].includes(item.status)).length}</sup>
                        </button>
                        <button
                            className="nav-link"
                            id="nav-contact-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-contact"
                            type="button"
                            role="tab"
                            aria-controls="nav-contact"
                            aria-selected="false"
                            onClick={() => this.filterOrders(['RECEIVED'])}
                        >
                            Received <sup>{this.props.orders.filter((item) => ['RECEIVED'].includes(item.status)).length}</sup>
                        </button>
                        <button
                            className="nav-link"
                            id="nav-contact-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-contact"
                            type="button"
                            role="tab"
                            aria-controls="nav-contact"
                            aria-selected="false"
                            onClick={() => this.filterOrders(['FINISHED', 'CANCEL_FINISHED', 'LOST','EXPIRED'])}
                        >
                            Completed <sup>{this.props.orders.filter((item) => ['FINISHED', 'CANCEL_FINISHED', 'LOST','EXPIRED'].includes(item.status)).length}</sup>
                        </button>
                        <button
                            className="nav-link"
                            id="nav-contact-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-contact"
                            type="button"
                            role="tab"
                            aria-controls="nav-contact"
                            aria-selected="false"
                            onClick={() => this.filterOrders(['CANCELLED', 'NOT_CONFIRMED'])}
                        >
                            Cancelled <sup>{this.props.orders.filter((item) => ['CANCELLED', 'NOT_CONFIRMED'].includes(item.status)).length}</sup>
                        </button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" onClick={() => {}}></div>
                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" onClick={() => {}}></div>
                    <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" onClick={() => {}}></div>
                </div>
                <div className="d-lg-none">
                    {ordersToMap &&
                        ordersToMap.map((original) => (
                            <div key={original.id} className="card table-row">
                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <span>Id</span>
                                        {original.id}
                                    </div>
                                    <div>
                                        <span>
                                            <Translation id="user" defaultMessage="User" />
                                        </span>
                                        <PublicProfileLink userNameSlug={original.sellerNameSlug} label={original.sellerName} />
                                    </div>
                                    <div>
                                        <span>
                                            <Translation id="type" defaultMessage="Type" />
                                        </span>
                                        <span style={{ textTransform: 'capitalize' }}>{original.type.toLowerCase()}</span>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="properties" style={{ display: 'flex', flexDirection: 'row' }}>
                                        {/* <RenderProductSingleProperties original={original} /> */}
                                        <div>
                                            <label>
                                                <Translation id="status" defaultMessage="Status" />
                                            </label>

                                            <div className="badge badge-primary" style={{ fontSize: '1em', fontWeight: 300 }}>
                                                <span style={{ textTransform: 'capitalize' }}>
                                                    {this.props.intl.formatMessage({ id: `order.status.${original.status.toLowerCase()}` })}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <label>
                                                <Translation id="date" defaultMessage="Date" />
                                            </label>
                                            {moment(original.created_at).format('DD/MM/YYYY')}
                                        </div>
                                        <div>
                                            <span style={{ textTransform: 'capitalize' }}>
                                                <Translation id="articles" defaultMessage="Articles" />
                                            </span>
                                            {original.total_articles}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <span>
                                            <Translation id="price" defaultMessage="Price" />
                                        </span>
                                        <strong>
                                            <DisplayPrice price={original.total_with_shipping_taxes}></DisplayPrice>
                                        </strong>
                                    </div>

                                    <div className="actions">
                                        <Button onClick={() => this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_ORDER' }).replace(':id', original.id))}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Translation id="view_more" defaultMessage="View more"></Translation>
                                                <span className="material-icons-outlined">chevron_right</span>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="d-none d-lg-block">
                    <ReactTable
                        data={this.state.ordersFiltered || this.props.orders}
                        // loading={this.props.isLoading}
                        // loadingText={<Spinner isLoading={this.props.isLoading} />}
                        // item.product.img
                        // item.product
                        // item.qty
                        // item.language
                        // item.status
                        // item.isSigned
                        // item.isPlayset
                        // item.isAltered
                        // item.isRated
                        // item.comments
                        columns={[
                            {
                                Header: <Translation id="id" defaultMessage="Id" />,
                                accessor: 'id',
                                minWidth: 40,
                            },
                            {
                                Header: <Translation id="type" defaultMessage="Type" />,
                                accessor: 'type',
                                Cell: ({ original }) => {
                                    return original.type
                                },
                                minWidth: 40,
                            },
                            {
                                Header: <Translation id="user" defaultMessage="User" />,
                                accessor: 'sellerName',
                                Cell: ({ original }) => {
                                    return original.sellerName
                                },
                                minWidth: 40,
                            },

                            // {
                            //     Header: <Translation id='shipping_method' defaultMessage='Shipping Method' />,
                            //     accessor: 'shipping_method',
                            //     className: 'text-center',
                            //     minWidth: 40
                            // },
                            {
                                Header: <Translation id="Status" defaultMessage="Status" />,
                                accessor: 'status',
                                Cell: ({ original }) => {
                                    return (
                                        <span style={{ textTransform: 'capitalize' }}>
                                            {this.props.intl.formatMessage({ id: `order.status.${original.status.toLowerCase()}` })}
                                        </span>
                                    )
                                },
                                className: 'text-center',
                                minWidth: 40,
                            },
                            {
                                Header: <Translation id="date" defaultMessage="Date" />,
                                accessor: 'created_at',
                                className: 'text-center',
                                minWidth: 80,
                                Cell: ({ original }) => {
                                    return moment(original.created_at).format('DD/MM/YYYY')
                                },
                            },
                            {
                                Header: <Translation id="articles" defaultMessage="Articles" />,
                                accessor: 'total_articles',
                                className: 'text-center',
                                minWidth: 40,
                            },
                            {
                                Header: <Translation id="price" defaultMessage="Price" />,
                                className: 'text-center',
                                accessor: 'price',
                                Cell: ({ original }) => {
                                    return original && <DisplayPrice price={original.total_with_shipping_taxes}></DisplayPrice>
                                },
                                minWidth: 40,
                                maxWidth: 120,
                            },

                            // {
                            //     Header: <Translation id="actions" />,

                            //     Cell: ({ original }) => {
                            //         return null
                            //     },
                            //     minWidth: 80,
                            // },
                        ]}
                        defaultPageSize={this.props.orders.length < 10 ? this.props.orders.length : 10}
                        pageSizeOptions={[10, 25, 50, 100]}
                        previousText={<Translation id="previous" defaultMessage="Previous" />}
                        nextText={<Translation id="next" defaultMessage="Next" />}
                        noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                        pageText={<Translation id="page" defaultMessage="Page" />}
                        ofText={<Translation id="of" defaultMessage="Of" />}
                        className="light table"
                        key={this.props.orders.length}
                        getTrProps={(state, rowInfo) => {
                            let cursor = rowInfo ? 'pointer' : 'default'
                            let background = 'white'
                            if (rowInfo && rowInfo.original) {
                                let background = this.props.userName === rowInfo.original.sellerName ? 'white' : '#efefef'
                            }

                            return {
                                onClick: (e) => {
                                    if (rowInfo.original.id) {
                                        this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_ORDER' }).replace(':id', rowInfo.original.id))
                                    }
                                },
                                style: { cursor: cursor, background: background },
                            }
                        }}
                        // filterable={true}
                    />
                </div>
            </div>
        )
    }
}

export default injectIntl(Orders)
